import { useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';

import FooterMenu from 'components/FooterMenu/FooterMenu';
import MenuButton from 'components/MenuButton/MenuButton';
import { useCopyStore } from 'store';
import { HeaderMenuProps } from 'store/copy.types';
import { ReactComponent as SvgLogo } from 'svgs/logo.svg';
import { useWindowSize } from 'u9/hooks';
import { ROUTES } from 'utils/routes';
import { fadeInRightMotion } from 'utils/styles/animations';
import { colors } from 'utils/styles/theme';

import * as S from './Header.styles';

export interface HeaderProps {}

const defaultProps: Partial<HeaderProps> = {};

const Header: FunctionComponent<HeaderProps> = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { copy } = useCopyStore();
  const router = useRouter();
  const { pathname } = router;
  const size = useWindowSize(true);

  const handleClick = (link: string) => {
    router.push(link);
    setOpen(false);
  };

  return (
    <S.Wrapper>
      <S.MenuButtonWrapper>
        <MenuButton
          key={`${size.width}-menu-button`}
          isOpen={isOpen}
          onClick={() => setOpen(!isOpen)}
          color={isOpen ? colors.alizarinCrimson : colors.white}
        />
      </S.MenuButtonWrapper>

      <S.MenuWrapper isOpen={isOpen}>
        {copy.header?.menu?.map(
          ({ name, link }: HeaderMenuProps, index: number) => (
            <S.Anchor
              key={index}
              tabIndex={0}
              onClick={() => handleClick(link)}
              onKeyPress={e => e.key === 'Enter' && handleClick(link)}
              isActive={pathname === link}
              isHome={pathname === ROUTES.HOME}
              {...fadeInRightMotion}
              transition={{
                delay: 0.1 * (index + 1),
                duration: 0.9,
              }}
            >
              {name}
            </S.Anchor>
          )
        )}
        <S.FooterMenuWrapper>
          <FooterMenu />
        </S.FooterMenuWrapper>
      </S.MenuWrapper>

      <S.LogoWrapper
        tabIndex={0}
        aria-label="humanDNA logo"
        onKeyDown={e => e.key === 'Enter' && handleClick(ROUTES.HOME)}
      >
        <SvgLogo onClick={() => handleClick(ROUTES.HOME)} />
      </S.LogoWrapper>
    </S.Wrapper>
  );
};

Header.defaultProps = defaultProps;

export default Header;
