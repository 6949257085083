import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import { useCopyStore } from 'store';
import { FooterMenuProps } from 'store/copy.types';
import { fadeInRightMotion } from 'utils/styles/animations';

import * as S from './FooterMenu.styles';

const defaultProps: Partial<FooterMenuProps> = {};

const FooterMenu: FunctionComponent = () => {
  const { copy } = useCopyStore();

  return (
    <S.Wrapper>
      {copy.footer.menu?.map(
        ({ name, link }: FooterMenuProps, index: number) => (
          <S.MenuItem
            key={index}
            {...fadeInRightMotion}
            transition={{
              delay: 0.1 * (index + 1),
              duration: 0.9,
            }}
          >
            {link ? (
              <Link href={link}>
                <a>{name}</a>
              </Link>
            ) : (
              <span>{name}</span>
            )}
          </S.MenuItem>
        )
      )}
    </S.Wrapper>
  );
};

FooterMenu.defaultProps = defaultProps;

export default FooterMenu;
