import styled from 'styled-components';

import { ColorNames, colors } from 'utils/styles/theme';

interface OvalButtonWrapperTypes {
  backgroundColor: ColorNames;
}

export const OvalButtonWrapper = styled.button<OvalButtonWrapperTypes>`
  width: fit-content;
  border-radius: 100px;
  text-align: center;
  border: 0.6px solid ${colors.white};
  display: grid;
  background: ${({ backgroundColor }) => colors[backgroundColor]};
  transition: opacity 0.1s linear, transform 0.1s linear;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transform: scale(1.02);
  }

  &:active {
    transform: scale(1.04);
  }
`;

export const Container = styled.div`
  padding: 8px 41px;
  display: grid;

  span {
    font-size: 16px;
    line-height: 2.045;
  }
`;
