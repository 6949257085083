import isValidProp from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import type { AppProps } from 'next/app';
import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Background from 'components/Background';
import Layout from 'components/Layout/Layout';
import { setupStoreDevTools, useCopyStore, useGlobalStore } from 'store';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
import Analytics from 'utils/analytics';
import { keyboardFocus } from 'utils/global';
import { ROUTES } from 'utils/routes';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);
  const { copy, setCopy } = useCopyStore();
  const { isModalVisible } = useGlobalStore();

  const keyboardEvent = useCallback(
    (e: KeyboardEvent) => keyboardFocus(e, { isModalVisible, router, copy }),
    [copy, isModalVisible, router]
  );

  useEffect(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();
    if (pageProps.initialCopy) setCopy(pageProps.initialCopy);
    if (process.env.NODE_ENV !== 'development')
      new Analytics()._initializeTracker();

    setMounted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyboardEvent, false);

    return () => document.removeEventListener('keydown', keyboardEvent, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: router.locale }}>
        <MotionConfig isValidProp={isValidProp}>
          <Background />
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />

          <Head {...(pageProps.initialCopy || copy).head} />

          <NonFunctionals
            // If the non-functionals are the page component, pass the initial copy
            // fetched from the server instead of the stored copy
            initialCopy={
              router.route === ROUTES.NOT_FOUND ? pageProps.initialCopy : copy
            }
            router={router}
          >
            <AnimatePresence exitBeforeEnter={true} initial={true}>
              <Layout>
                <Component key={router.route} router={router} {...pageProps} />
              </Layout>
            </AnimatePresence>
          </NonFunctionals>

          {process.env.IS_DEBUG && <Version />}
          {isMounted
            ? process.env.IS_DEBUG &&
              window.location.hash === '#version' && <VersionScreen />
            : null}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
