import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import CookieBanner from 'components/CookieBanner/CookieBanner';
import Footer from 'components/Footer/Footer';
import FooterMenu from 'components/FooterMenu/FooterMenu';
import Header from 'components/Header/Header';
import Modal from 'components/Modal/Modal';
import { ROUTES } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';

import * as S from './Layout.styles';

export interface LayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<LayoutProps> = {};

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { pathname } = useRouter();

  const contactPageBottomBar = (
    <S.BottomBar>
      <FooterMenu />
    </S.BottomBar>
  );

  return (
    <S.Wrapper
      darkBackground={[ROUTES.CASE_STUDY as string].includes(pathname)}
    >
      <Header />
      <S.Content {...pageMotionProps}>{children}</S.Content>
      {pathname !== ROUTES.CONTACT ? <Footer /> : contactPageBottomBar}
      {[ROUTES.HOME as string, ROUTES.SERVICES].includes(pathname) && <Modal />}
      <CookieBanner />
    </S.Wrapper>
  );
};

Layout.defaultProps = defaultProps;

export default Layout;
