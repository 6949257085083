import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export enum ColorPicker {
  red = 'red',
  grey = 'grey',
  transparent = 'transparent',
}

const colorPicker = {
  [ColorPicker.red]: colors.tallPoppy,
  [ColorPicker.grey]: colors.outerSpace,
  [ColorPicker.transparent]: 'transparent',
};

export const Wrapper = styled.div`
  max-width: 1920px;
  display: grid;
  grid-template-areas:
    'logo'
    'socials'
    'locations'
    'menu';
  grid-template-columns: 1fr;
  position: relative;

  ${mediaTablet(`
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
    'logo locations socials'
    'menu menu menu';
  `)}
`;

export const DefaultWrapper = styled(Wrapper)`
  width: 100%;
  gap: 110rem;
  padding: 137rem 82rem 110rem;

  ${mediaTablet(`
    gap: 93rem;
    padding: 100rem 55rem 68rem;
  `)}

  ${mediaDesktop(`
    padding: 124rem 100rem 68rem;
  `)}
`;

export const SocialMediaWrapper = styled.div`
  flex-wrap: wrap;
  grid-area: socials;
  & > div {
    & > div:first-of-type {
      width: 100%;
    }
  }

  ${mediaDesktop(`
    flex-wrap: nowrap;
    & > div {
      & > div:first-of-type {
        width: auto;
      }
    }
  `)}
`;

export const ContactPageWrapper = styled(Wrapper)`
  width: 100%;
  padding: 0 82rem 273rem;

  ${SocialMediaWrapper} {
    margin-bottom: 273rem;
  }

  ${mediaTablet(`
    padding: 100rem 55rem 68rem;
    margin-bottom: 0;
  `)}

  ${mediaDesktop(`
    padding: 124rem 100rem 68rem;
  `)}
`;

export const LocationsWrapper = styled.div`
  grid-area: locations;
`;

export const LogoWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  color: ${colors.white};
  grid-area: logo;

  svg {
    width: 100%;
    height: 100%;
    max-height: 82rem;
    max-width: 317rem;
  }

  ${mediaTablet(`
    margin-bottom: 132rem;

    svg {
      width: 100%;
      height: 100%;
      max-height: 85rem;
      max-width: 327rem;
    }
  `)}
`;

export const MenuWrapper = styled.div`
  width: 100%;
  grid-area: menu;
  margin-top: 85rem;
`;

export const Content = styled.footer<{
  backgroundColor: ColorPicker.red | ColorPicker.grey | ColorPicker.transparent;
}>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: ${({ backgroundColor }) => colorPicker[backgroundColor]};
`;
