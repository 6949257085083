export default `
  @font-face {
    font-family: 'Urbanist';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Urbanist/Urbanist-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Urbanist';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Urbanist/Urbanist-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Urbanist';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/Urbanist/Urbanist-Bold.ttf') format('truetype');
  }

  // Neue Helvetica
    @font-face{
        font-family:"Neue Helvetica W05_29 Cm Ult L";
        src:url("fonts/Neue Helvetica/5164073/9c4783fd-4d57-415c-83f8-fbcd4632290b.woff2") format("woff2"),url("fonts/Neue Helvetica/5164073/9b248368-d8c2-4278-8524-f14ac5bbf7c2.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_39 Comp Thi";
        src:url("fonts/Neue Helvetica/5165311/642a878f-7553-4f21-a375-7f431f169eeb.woff2") format("woff2"),url("fonts/Neue Helvetica/5165311/ed2003ed-8181-4c1c-9cb4-cc83ffe7aecb.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_49 Cm Light";
        src:url("fonts/Neue Helvetica/5165808/b08de6fb-48b1-4f21-a5e2-9f06002c65de.woff2") format("woff2"),url("fonts/Neue Helvetica/5165808/9350baa2-4e36-4a96-9418-f9920966ab64.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_89 Cm Heavy";
        src:url("fonts/Neue Helvetica/5222633/37affb68-d2dd-460e-8248-b699baced865.woff2") format("woff2"),url("fonts/Neue Helvetica/5222633/675d0ef2-6dea-42ca-80fd-175b687eac14.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_79 Comp Bol";
        src:url("fonts/Neue Helvetica/5313910/58583898-0494-4579-bafb-d504aa8cc480.woff2") format("woff2"),url("fonts/Neue Helvetica/5313910/b2053850-e2c0-44fa-a8af-8b299aa6fdb0.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_69 Cm Mediu";
        src:url("fonts/Neue Helvetica/5318330/d630513e-94d0-4fed-9497-bc762577c07f.woff2") format("woff2"),url("fonts/Neue Helvetica/5318330/b9351fa0-4d79-45f7-9236-28323476e99f.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05_99 Cm Black";
        src:url("fonts/Neue Helvetica/5344947/01b2cbed-29f5-498d-ad87-e567fe871fc1.woff2") format("woff2"),url("fonts/Neue Helvetica/5344947/708ab8e2-2120-4b70-9c16-0fc203f1ec9c.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue for IB W05 Rg";
        src:url("fonts/Neue Helvetica/5383874/c4bf3e0d-88d7-4639-b818-04f066d64256.woff2") format("woff2"),url("fonts/Neue Helvetica/5383874/0de80af0-c8ac-4342-ba58-30306d607658.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue for HUKW0577BdC";
        src:url("fonts/Neue Helvetica/5496391/bd27a441-6540-449d-aa7b-7a08272fa526.woff2") format("woff2"),url("fonts/Neue Helvetica/5496391/345e42ba-c6e5-4d7b-bd83-4551400a4246.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 57 Cn Obl";
        src:url("fonts/Neue Helvetica/5658394/955e2e4b-4004-4325-a6a1-80acc54ff5c0.woff2") format("woff2"),url("fonts/Neue Helvetica/5658394/f6047d08-65e9-4b62-8147-b118b85d8deb.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 67MdCnObl";
        src:url("fonts/Neue Helvetica/5658401/9c31450f-5d89-4ebd-afc6-9962b19448f5.woff2") format("woff2"),url("fonts/Neue Helvetica/5658401/d0d30f97-b639-4228-8b85-fe2f879968db.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 87HvCnObl";
        src:url("fonts/Neue Helvetica/5658408/9cc465b5-d49c-435c-86f2-52b4724e4e13.woff2") format("woff2"),url("fonts/Neue Helvetica/5658408/9b01165b-5359-4cf6-8222-e6b0dc4dfa54.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W0597BlkCnObl";
        src:url("fonts/Neue Helvetica/5658415/635ef666-139c-4f6c-a671-648acbcd5619.woff2") format("woff2"),url("fonts/Neue Helvetica/5658415/e4933688-4bbe-4e73-9faf-13865c4eed39.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica NeueLTW0523UltLtExObl";
        src:url("fonts/Neue Helvetica/5658422/7494fa52-ef60-4526-ae97-6a79476f65d1.woff2") format("woff2"),url("fonts/Neue Helvetica/5658422/a97edc7d-e8eb-4e13-bccf-2762d3edad44.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 33ThExObl";
        src:url("fonts/Neue Helvetica/5658429/cdf91fc1-04f6-4402-b94c-e943abfa7dea.woff2") format("woff2"),url("fonts/Neue Helvetica/5658429/36b66102-f03a-4210-bbee-1c610abba3e3.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 73BdExObl";
        src:url("fonts/Neue Helvetica/5658436/a9a0e1ca-8be8-4441-8165-dfca8a447fe9.woff2") format("woff2"),url("fonts/Neue Helvetica/5658436/23ffc3f6-e1a7-4b01-b5a8-af27dcd7921c.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 83HvExObl";
        src:url("fonts/Neue Helvetica/5658443/1250e9a0-b13e-46b1-9c3f-60e487e7c715.woff2") format("woff2"),url("fonts/Neue Helvetica/5658443/9eee9642-ade0-42ba-8906-a13a54c2422a.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W0593BlkExObl";
        src:url("fonts/Neue Helvetica/5658450/6f623488-6c71-4b67-be62-9a2b09c2f56a.woff2") format("woff2"),url("fonts/Neue Helvetica/5658450/6527f829-2c75-49cb-b90f-a7c3f19366f6.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_37ThCnOb";
        src:url("fonts/Neue Helvetica/5663700/cec55e42-b740-41ea-bb12-f45324e59638.woff2") format("woff2"),url("fonts/Neue Helvetica/5663700/5e3b3ff0-2fac-45a2-9e6c-b8c2e8d6705a.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_47 Lt Cn";
        src:url("fonts/Neue Helvetica/5663704/083e1179-98b4-4cbd-a13a-50a7c78d6fea.woff2") format("woff2"),url("fonts/Neue Helvetica/5663704/239170e6-7f0d-4c35-97d4-490b362e4ef7.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_47LtCnOb";
        src:url("fonts/Neue Helvetica/5663709/4e0d849a-b425-400e-a40c-789c4a37aac0.woff2") format("woff2"),url("fonts/Neue Helvetica/5663709/71ce5b5d-325e-417b-843b-e8f6e554a182.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_75 Bd Ou";
        src:url("fonts/Neue Helvetica/5663717/933a0af8-7461-40c7-acc4-9fc9a70aca3a.woff2") format("woff2"),url("fonts/Neue Helvetica/5663717/6249015c-3be5-4b2d-822b-f43bf7f278f4.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_27UltLtC";
        src:url("fonts/Neue Helvetica/5663722/fb306239-7f59-4f44-b617-23c761ba3cb1.woff2") format("woff2"),url("fonts/Neue Helvetica/5663722/a3af510f-3434-46ba-873d-bc503a1a8b4e.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica NeueLTW0527UltLtCnOb";
        src:url("fonts/Neue Helvetica/5663727/cd911115-5657-4542-86e9-b5aa4cbcced7.woff2") format("woff2"),url("fonts/Neue Helvetica/5663727/2316696f-3b84-4d49-b666-bf2d97f338e6.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_37 Th Cn";
        src:url("fonts/Neue Helvetica/5663731/9d1ff4e9-ed81-4251-88c5-c9c1352a215b.woff2") format("woff2"),url("fonts/Neue Helvetica/5663731/97135348-a627-408f-8626-ad4f413621b7.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_57 Cond";
        src:url("fonts/Neue Helvetica/5663732/9516ef74-4fe9-45fe-a09f-493cb9518f71.woff2") format("woff2"),url("fonts/Neue Helvetica/5663732/5b99bc59-1b2e-446b-961e-2e1dbdfcd641.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_67 Md Cn";
        src:url("fonts/Neue Helvetica/5663739/1a559dd9-e51e-45d5-86eb-f343223bacef.woff2") format("woff2"),url("fonts/Neue Helvetica/5663739/581fae27-b5f4-44ab-b5b1-92f11d4f9838.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_77BdCnOb";
        src:url("fonts/Neue Helvetica/5663751/83988233-0e17-4c53-b35a-2f1c0c2a3ca1.woff2") format("woff2"),url("fonts/Neue Helvetica/5663751/22f5df0d-ec99-4f2e-a776-ca2bdf0df48a.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_87 Hv Cn";
        src:url("fonts/Neue Helvetica/5663755/28343101-8a93-4048-85b0-ef49feebe7bf.woff2") format("woff2"),url("fonts/Neue Helvetica/5663755/799e7d1f-b263-4621-9776-df01ebcff3e2.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_97 Blk C";
        src:url("fonts/Neue Helvetica/5663763/f2f49a5e-567d-4dfa-8152-c28c8f60c84e.woff2") format("woff2"),url("fonts/Neue Helvetica/5663763/ea7ce364-9de4-43c3-9b0b-7b26af453629.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_107XBlkC";
        src:url("fonts/Neue Helvetica/5663771/91164c5b-326b-430d-a6bf-9415d6b57c42.woff2") format("woff2"),url("fonts/Neue Helvetica/5663771/7de96184-e0a5-4413-8dc0-5c15619cef19.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica NeueLTW05107XBlkCnOb";
        src:url("fonts/Neue Helvetica/5663776/932d2296-d705-410a-8e4c-d68f32085933.woff2") format("woff2"),url("fonts/Neue Helvetica/5663776/06b24b76-8a44-4b13-81cf-01630e8de97d.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_23UltLtE";
        src:url("fonts/Neue Helvetica/5663813/7efec9b7-1374-4adc-8d45-514361fd48b8.woff2") format("woff2"),url("fonts/Neue Helvetica/5663813/06b4e4fc-e732-41a7-a362-be9f88aadc89.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_33 Th Ex";
        src:url("fonts/Neue Helvetica/5663820/2f270d4e-e43c-457e-ba8a-0dd33bea037a.woff2") format("woff2"),url("fonts/Neue Helvetica/5663820/4595f8b8-db8c-4c01-a7a4-9bb031810849.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_43 Lt Ex";
        src:url("fonts/Neue Helvetica/5663828/86578ae8-4abb-42d0-8784-375d07db941f.woff2") format("woff2"),url("fonts/Neue Helvetica/5663828/18b6ae6b-f0cf-422b-bf3f-35be98be3c19.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_43LtExOb";
        src:url("fonts/Neue Helvetica/5663833/c12fc731-15eb-44eb-94a1-fe233d5665b9.woff2") format("woff2"),url("fonts/Neue Helvetica/5663833/fde07ba4-9a82-44e0-8f27-969ec84addaa.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_53 Ext";
        src:url("fonts/Neue Helvetica/5663837/0504481e-774c-4e57-a055-c94bb5303380.woff2") format("woff2"),url("fonts/Neue Helvetica/5663837/bf166391-2bf9-47fd-8382-193fffb422c4.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_53 Ex Ob";
        src:url("fonts/Neue Helvetica/5663842/d829f114-8f21-4a35-a303-5fc44e646b98.woff2") format("woff2"),url("fonts/Neue Helvetica/5663842/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_63 Md Ex";
        src:url("fonts/Neue Helvetica/5663846/5069b541-02d6-4f13-9ec2-07e07e9d683f.woff2") format("woff2"),url("fonts/Neue Helvetica/5663846/cef4a236-7e60-42cd-997c-99487ff6bb0a.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_63MdExOb";
        src:url("fonts/Neue Helvetica/5663854/bdac6a25-6e2e-444a-bf57-324e1c75aebe.woff2") format("woff2"),url("fonts/Neue Helvetica/5663854/c92fa14b-1125-4b35-af82-e201823dd789.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_73 Bd Ex";
        src:url("fonts/Neue Helvetica/5663858/3e37e0b1-8062-42c6-8664-4ea78aa2de25.woff2") format("woff2"),url("fonts/Neue Helvetica/5663858/a25e22f2-80cf-4f21-a365-b77c87a3edf8.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_83 Hv Ex";
        src:url("fonts/Neue Helvetica/5663862/37cade8a-4499-42b4-b79f-99143dd3a764.woff2") format("woff2"),url("fonts/Neue Helvetica/5663862/6823dde3-d344-4bb4-b5a4-add12527140d.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05_93 Blk E";
        src:url("fonts/Neue Helvetica/5663870/275e1e1e-08b1-4fe8-952b-5484d602fa4e.woff2") format("woff2"),url("fonts/Neue Helvetica/5663870/4996b52c-3eb3-43f9-b701-452d8179a1de.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 25 Ult Lt";
        src:url("fonts/Neue Helvetica/5664070/ec6281a0-c9c4-4477-a360-156acd53093f.woff2") format("woff2"),url("fonts/Neue Helvetica/5664070/11066b40-10f7-4123-ba58-d9cbf5e89ceb.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 26UltLtIt";
        src:url("fonts/Neue Helvetica/5664077/2707a251-2d32-4bb6-a3c4-87114ba2365f.woff2") format("woff2"),url("fonts/Neue Helvetica/5664077/40f50724-486b-4e7b-9366-237e06eabfc8.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 45 Light";
        src:url("fonts/Neue Helvetica/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),url("fonts/Neue Helvetica/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 56 Italic";
        src:url("fonts/Neue Helvetica/5664098/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"),url("fonts/Neue Helvetica/5664098/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 95 Black";
        src:url("fonts/Neue Helvetica/5664121/fc4fb6ca-f981-4115-b882-c78e9f08be52.woff2") format("woff2"),url("fonts/Neue Helvetica/5664121/6ed03453-f512-45ba-84bf-fe4ea45d5e6a.woff") format("woff");
    }
    @font-face{
        font-family:"Helvetica Neue LT W05 96 Blk It";
        src:url("fonts/Neue Helvetica/5664128/995add04-59cc-41ea-abd2-4712eaddf2a8.woff2") format("woff2"),url("fonts/Neue Helvetica/5664128/7090e465-f6bf-4664-8b5a-d877a6915d87.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Medium Italic";
        src:url("fonts/Neue Helvetica/6203240/d5a7e947-43d7-4506-8093-046aafeeaa0d.woff2") format("woff2"),url("fonts/Neue Helvetica/6203240/723dc21d-948f-4fc1-a587-1d9c6407eed3.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Thin";
        src:url("fonts/Neue Helvetica/6203244/9556aace-5351-4821-ab83-99468f215fd7.woff2") format("woff2"),url("fonts/Neue Helvetica/6203244/59dfe337-edaa-4167-8542-22fc7bba0de2.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Thin Italic";
        src:url("fonts/Neue Helvetica/6203248/9226b900-1c4c-46b9-842d-5ff4d098876c.woff2") format("woff2"),url("fonts/Neue Helvetica/6203248/eaaf452e-fd13-41d5-b45d-d9c2cba063ef.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Light Italic";
        src:url("fonts/Neue Helvetica/6203252/98d4a59d-4bb5-4759-948d-955508ae66eb.woff2") format("woff2"),url("fonts/Neue Helvetica/6203252/cc47ebfe-4f83-4363-8ddc-782fc8ce5e91.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Heavy";
        src:url("fonts/Neue Helvetica/6203258/c81f17ec-9db8-423a-a8c3-e8f3afe35e1e.woff2") format("woff2"),url("fonts/Neue Helvetica/6203258/51f1601f-32d4-4cdb-bf53-4e8176a33559.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Bold Italic";
        src:url("fonts/Neue Helvetica/6203262/94ce0499-581a-41a8-b467-af9e4c7cf54b.woff2") format("woff2"),url("fonts/Neue Helvetica/6203262/354596a1-3ef3-4231-8f9e-d3e3a3e6b776.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Bold";
        src:url("fonts/Neue Helvetica/6203266/8b09485a-886b-4857-96c0-958fec1d551c.woff2") format("woff2"),url("fonts/Neue Helvetica/6203266/53e1651f-8959-452e-a848-d892d5390eaa.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Medium";
        src:url("fonts/Neue Helvetica/6203270/4cf8968c-c0ef-4766-bcca-b1b1aa579620.woff2") format("woff2"),url("fonts/Neue Helvetica/6203270/b691e4b9-6db9-4257-be03-562a235c7a91.woff") format("woff");
    }
    @font-face{
        font-family:"CB Sans W05 Heavy Italic";
        src:url("fonts/Neue Helvetica/6203276/e1b520b7-ce9f-4d7e-a520-b66508de35da.woff2") format("woff2"),url("fonts/Neue Helvetica/6203276/2afb2eb8-525d-4bba-8d0c-46344ab38db6.woff") format("woff");
    }
    @font-face{
        font-family:"Neue Helvetica W05 59 Comp";
        src:url("fonts/Neue Helvetica/6254522/280620ef-b205-4a7f-a09a-c095e6184a65.woff2") format("woff2"),url("fonts/Neue Helvetica/6254522/5a532f4a-8560-49d9-b223-3ae788d801e8.woff") format("woff");
    }
`;
