import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  withIcon?: boolean;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  height: 138rem;
  width: ${({ withIcon }) => (withIcon ? '138rem' : 'auto')};
  padding: ${({ withIcon }) => (withIcon ? '15rem' : '0 55rem')};
  border: solid 1px ${colors.swamp};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${setTypography('button')};

  a {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ${({ withIcon }) =>
    withIcon
      ? `
    width: 148rem;
    padding: 28.7rem;

    ${mediaTablet`
      width: 88rem;
      padding: 19.3rem;
    `}
  `
      : `
    width: auto;
    padding: 0 77rem;

    ${mediaTablet`
      padding: 0 55rem;
    `}
  `};

  ${mediaTablet(`
    height: 88rem;
  `)}
`;
