import styled from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface SoundButtonWrapperProps {
  isMobileLayout: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const ClickPoint = styled.div`
  width: 100%;
  height: 85%;
  position: absolute;
  inset: 0;
  z-index: 3;
`;

export const IconWrapper = styled.div`
  width: 250rem;
  height: 253rem;
  position: absolute;
  inset: 0;
  margin: auto;
  color: ${colors.alizarinCrimson};
  z-index: 2;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SoundButtonWrapper = styled.div<SoundButtonWrapperProps>`
  position: fixed;
  z-index: 1000;

  min-height: 96rem;
  top: 0;
  left: 0;

  ${({ isMobileLayout }) =>
    mediaTablet(`
    ${!isMobileLayout && 'position: absolute;'}
  `)}

  svg {
    width: 96rem;
    height: 96rem;
    margin: 36rem 0 36rem 36rem;
    cursor: pointer;
  }
`;
