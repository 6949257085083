export const defaultFontSize = 24; // px

export const breakpointTablet = 712; // px
export const breakpointDesktop = 1025; // px
export const breakpointDesktopLarge = 1280; // px
export const breakpointDesktopExtraLarge = 1920; // px
export const breakpointDesktopSuperExtraLarge = 2800; // px

export const desktopMinHeight = 600; // px
export const breakpointMobileSmallHeight = 600; // px

export const mobileBaseWidth = 1125; // px
export const desktopBaseWidth = 1320; // px

export const mobileMinFontSize = 11; // px
export const desktopMinFontSize = 12; // px

export const mobileScalableFontSize = 110 / mobileBaseWidth; // vw
export const mobileSmallHeightScalableFontSize = 0.0525; // vw
export const tabletScalableFontSize = 0.05; // vw
export const desktopScalableFontSize = 40 / desktopBaseWidth; // vw
// export const desktopWideScalableFontSize = 0.027; // vw
export const desktopWideScalableFontSize = 0.0303; // vw
export const desktopExtraWideScalableFontSize = 0.0243; // vw
export const desktopSuperExtraWideScalableFontSize = 0.0173; // vw

export const desktopWideAspectRatio = '20 / 11';

export const videoPlayerSizes = {
  width: '1776rem',
  height: '1020rem',
};
