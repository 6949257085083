import { motion } from 'framer-motion';
import styled from 'styled-components';

import Text from 'components/Text/Text';
import { setVh } from 'utils/styles/mixins';
import {
  mediaDesktop,
  mediaMobileSmallHeight,
  mediaPortrait,
  mediaTablet,
} from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';
import { videoPlayerSizes } from 'utils/styles/vars';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface PreviewWrapperProps {
  rightSide?: boolean;
}

export const Wrapper = styled(motion.div)`
  background: linear-gradient(180deg, rgba(0, 11, 18, 0.9) 0%, #000b12 100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${setVh(100)};
  max-height: ${setVh(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 55rem;
  z-index: 100;
  overflow-y: auto;

  ${mediaTablet(`
    padding: 0;
  `)}
`;

export const OuterBox = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;

  ${mediaTablet(`
    align-items: center;
    max-height: 100vw;
    transform: translateY(0%);
  `)}

  ${mediaDesktop(`
    padding: 110rem 0;
    max-height: 100vw;
    transform: translateY(0%);
  `)}

  ${mediaMobileSmallHeight(`
    align-items: flex-start;
  `)}
`;

// INFO: After styles changes, change also styles in CardGrid.styles.ts in Pointer. It's important for open animation.
export const InnerBox = styled(motion.div)`
  width: ${videoPlayerSizes.width} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: transparent;
  padding: 110rem 0 55rem;
  overflow-y: auto;

  ${mediaTablet(`
    padding: 110rem 0 55rem;
    margin-top: 260rem;
    overflow: hidden;
    cursor: pointer;
  `)}

  ${mediaDesktop(`
    padding: 110rem 0;
    margin-top: 472rem;
  `)}
`;

export const PlayerWrapper = styled(motion.div)<{ isMobile?: boolean }>`
  border-radius: 20rem;
  overflow: hidden;

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 100vw;
    height: ${setVh(100)};
    position: fixed;
    inset: 0;
    margin: auto;
  `
      : `
    width: 100%;
    height: ${videoPlayerSizes.height};
    position: relative;
  `}

  & > div:last-of-type {
    width: 100%;
    height: 100%;
  }

  iframe {
    max-width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TitleWrapper = styled(motion.div)`
  display: block;

  & > label {
    display: block;
  }

  & > p:last-of-type {
    display: inline-block;
  }
`;

export const DescriptionWrapper = styled(motion.div)``;

export const SvgWrapper = styled(motion.div)``;

export const ContentWrapper = styled(motion.div)<{ isMobile?: boolean }>`
  display: grid;

  ${({ isMobile }) =>
    isMobile
      ? `
    width: 50%;
    height: 100%;
    display: grid;
    gap: 130rem;
    position: fixed;
    top: 0;
    right: -50%;
    background: rgba(0, 0, 0, 0.6);
    padding: 110rem 110rem 0;
    overflow-y: auto;

    z-index: 10;

    ${DescriptionWrapper} > p {
      font-size: 50rem;
      line-height: 1.68;
    }

    ${TitleWrapper} > p {
      font-size: 96rem;
      line-height: 0.8236;
      margin-top: 24rem;
    }


    ${mediaPortrait(`
      width: 100%;
      right: -100%;
    `)}
  `
      : `
    width: 100%;
    gap: 60rem;
    grid-template-columns: 100%;
    padding: 0 106rem;
    margin-top: 60rem;
  `};

  ${mediaDesktop(`
    min-height: 242rem;
    grid-template-columns: 38% auto;
  `)}
`;

export const TextComp = styled(Text)`
  margin-top: 18rem;
  cursor: pointer;
`;

export const CloseButtonWrapper = styled(motion.button)<{ isMobile?: boolean }>`
  position: absolute;
  z-index: 1000;

  ${({ isMobile }) =>
    isMobile
      ? `
    min-height: 96rem;
    top: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    svg {
      width: 96rem;
      height: 96rem;
      margin: 36rem 36rem 36rem 0;
      cursor: pointer;
    }
  `
      : `
    width: 60rem;
    height: 60rem;
    top: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: ${colors.alizarinCrimson};

    svg {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    ${mediaTablet(`
      width: 110rem;
      height: 114rem;
    `)}
  `}
`;

export const IconWrapper = styled(motion.div)<{ leftSide?: boolean }>`
  width: 110rem;
  height: 110rem;
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ leftSide }) =>
    leftSide
      ? `
      right: 320rem;
  `
      : `
      left: 320rem;
  `};
  margin: auto;
  opacity: 0;
  color: ${colors.alizarinCrimson};
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const PreviewWrapper = styled(motion.div)<PreviewWrapperProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
  z-index: 1003;
  cursor: pointer;
  background: ${colors.gallery};
  border-radius: 20rem;
  overflow: hidden;
  display: none;

  ${({ rightSide }) =>
    rightSide
      ? `
    right: -100rem;
  `
      : `
    left: -100rem;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  ${IconWrapper} {
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.1, 1.1);
    }

    ${IconWrapper} {
      opacity: 1;
    }
  }

  ${mediaTablet(`
    display: grid;
  `)}
`;

export const CarouselWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 120rem;

  & .slick-slider {
    width: 100%;

    & .slick-slide {
      display: flex;
      justify-content: center;
    }
  }

  & .slick-current,
  & .slick-center {
    position: relative;
    z-index: 1;
  }

  & .slick-list {
    margin: 0 -1250rem;
  }

  & .slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  & .activeSlide {
    transform: scale(1);
    opacity: 1;
  }
`;

export const MobileArrowsWrapper = styled.div`
  width: 95%;
  height: 50rem;
  display: flex;
  justify-content: space-between;

  position: fixed;
  inset: 0;
  z-index: 1;

  margin: auto;

  svg {
    color: ${colors.white};
    opacity: 0.4;
    cursor: pointer;

    &:first-child {
      transform: rotate(180deg);
    }
  }
`;
