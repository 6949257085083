import { useRouter } from 'next/router';
import React, { FunctionComponent, memo } from 'react';

import FooterMenu from 'components/FooterMenu/FooterMenu';
import Locations from 'components/Locations/Locations';
import SocialMedia from 'components/SocialMedia/SocialMedia';
import { useCopyStore } from 'store';
import { ReactComponent as SvgLogo } from 'svgs/logoFooter.svg';
import { ROUTES } from 'utils/routes';

import { ColorPicker } from './Footer.styles';
import * as S from './Footer.styles';

export interface FooterProps {}

const defaultProps: Partial<FooterProps> = {};

const Footer: FunctionComponent<FooterProps> = () => {
  const { copy } = useCopyStore();
  const { pathname } = useRouter();

  const isContactPage = pathname === ROUTES.CONTACT;

  const Wrapper = ({ children }) =>
    isContactPage ? (
      <S.Content backgroundColor={ColorPicker.transparent}>
        <S.ContactPageWrapper>{children}</S.ContactPageWrapper>
      </S.Content>
    ) : (
      <S.Content backgroundColor={copy.footer.backgroundColor}>
        <S.DefaultWrapper>{children}</S.DefaultWrapper>
      </S.Content>
    );

  return (
    <Wrapper>
      {!isContactPage && (
        <S.LogoWrapper>
          <a
            href="https://www.humanworldwide.com"
            target="_blank"
            rel="noreferrer"
          >
            <SvgLogo />
          </a>
        </S.LogoWrapper>
      )}

      <S.SocialMediaWrapper>
        <SocialMedia white />
      </S.SocialMediaWrapper>

      <S.LocationsWrapper>
        <Locations white />
      </S.LocationsWrapper>

      {!isContactPage && (
        <S.MenuWrapper>
          <FooterMenu />
        </S.MenuWrapper>
      )}
    </Wrapper>
  );
};

Footer.defaultProps = defaultProps;

export default memo(Footer);
