import { useRouter } from 'next/router';
import React from 'react';

import FooterMenu from 'components/FooterMenu/FooterMenu';
import Header from 'components/Header/Header';
import LinkButton from 'components/LinkButton/LinkButton';
import Text from 'components/Text/Text';
import { useCopyStore } from 'store';
import { ROUTES } from 'utils/routes';
import { fadeInUpMotion, pageMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as S from './NotFound.styles';

const NotFound = () => {
  const router = useRouter();
  const { copy } = useCopyStore();

  if (!copy.notFound) return null;

  return (
    <S.Wrapper {...pageMotionProps}>
      <Header />
      <S.ContentWrapper>
        <S.TextWrapper
          {...fadeInUpMotion}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          <Text
            as="h1"
            text={copy.notFound.title}
            color={ColorNames.swamp}
            size="notFound"
          />
        </S.TextWrapper>

        <S.TextWrapper
          {...fadeInUpMotion}
          transition={{ delay: 0.9, duration: 0.6 }}
        >
          <LinkButton
            onClick={() => router.push(ROUTES.HOME)}
            text={copy.notFound.linkText}
            size="heading5"
          />
        </S.TextWrapper>
      </S.ContentWrapper>

      <S.BottomBar>
        <FooterMenu />
      </S.BottomBar>
    </S.Wrapper>
  );
};

export default NotFound;
