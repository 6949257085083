import React, { FunctionComponent } from 'react';

import { ColorNames, validFontCategories } from 'utils/styles/theme';

import * as S from './Text.styles';

export interface TextProps {
  text: string;
  size: typeof validFontCategories[number];
  color?: ColorNames;
  as?: any;
  [x: string]: any;
}

const defaultProps: Partial<TextProps> = {
  color: ColorNames.white,
  size: 'body1',
};

const Text: FunctionComponent<TextProps> = ({
  text,
  size,
  as,
  color,
  ...rest
}) => {
  return (
    <S.Wrapper
      as={as}
      size={size}
      color={color}
      dangerouslySetInnerHTML={{ __html: text }}
      {...rest}
    />
  );
};

Text.defaultProps = defaultProps;

export default Text;
