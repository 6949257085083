import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGlobalStore } from 'store';
import { ProjectProps } from 'store/copy.types';

const INITIAL_PREVIEWS_STATE = {
  prev: null,
  next: null,
};

interface ModalSlidesHook {
  previews: PreviewProps;
  prev: () => void;
  next: () => void;
}

interface PreviewProps {
  prev: ProjectProps | null;
  next: ProjectProps | null;
}

const useSlides = (): ModalSlidesHook => {
  const [previews, setPreviews] = useState<PreviewProps>(
    INITIAL_PREVIEWS_STATE
  );

  const { isModalVisible, filteredItems, selectedProject, setSelectedProject } =
    useGlobalStore();

  const slides = useMemo(() => {
    const activeIndex = filteredItems.findIndex(
      project => project.id === selectedProject?.id
    );

    const prevProject = filteredItems[activeIndex - 1];
    const nextProject = filteredItems[activeIndex + 1];

    const prev = prevProject || null;
    const next = nextProject || null;

    return {
      prev,
      next,
    };
  }, [filteredItems, selectedProject]);

  useEffect(() => {
    if (isModalVisible) {
      setPreviews(slides);
    }
  }, [isModalVisible, selectedProject, filteredItems]);

  const prev = useCallback(() => {
    slides.prev && setSelectedProject(slides.prev);
  }, [filteredItems, selectedProject]);

  const next = useCallback(() => {
    slides.next && setSelectedProject(slides.next);
  }, [filteredItems, selectedProject]);

  return { previews, prev, next };
};

export default useSlides;
