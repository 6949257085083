import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, validFontCategories } from 'utils/styles/theme';

interface WrapperProps {
  color: ColorNames;
  size: typeof validFontCategories[number];
  withUnderline?: boolean;
}

export const Wrapper = styled(motion.p)<WrapperProps>`
  ${({ size }) => setTypography(size)};
  color: ${({ color }) => colors[color]};
  user-select: none;
  text-decoration: none;
  margin: 0;
  position: relative;

  ${({ withUnderline }) =>
    withUnderline &&
    `
    cursor: pointer;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -30rem;
      height: 3px;
      background: ${colors.alizarinCrimson};
    }
  `}
`;
