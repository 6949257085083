import { motion } from 'framer-motion';
import styled from 'styled-components';

import { fullscreenBlack, setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100vw;
  min-height: ${setVh(100)};
  height: 100%;
`;

export const ContentWrapper = styled(motion.div)`
  ${fullscreenBlack()};

  h1 {
    margin: 15rem 0 0;
  }

  h4:after {
    width: 100%;
    bottom: -35rem;
  }
`;

export const TextWrapper = styled(motion.div)`
  h1 {
    background: linear-gradient(
      180deg,
      #dc2424 10.44%,
      rgba(220, 36, 36, 0) 93.31%
    );
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    color: transparent;
  }

  h4 {
    font-size: 127.0589rem;
    line-height: 0.85;
  }
`;

export const BottomBar = styled(motion.div)`
  height: 279rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 55rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.tallPoppy};

  & > div {
    width: 100%;
  }

  ${mediaTablet(`
    height: 159rem;
    padding: 0 102rem;
  `)}
`;
