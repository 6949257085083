import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player/lazy';

import useCopyStore from 'store/copy';
import { ReactComponent as SvgMute } from 'svgs/mute.svg';
import { ReactComponent as SvgPlay } from 'svgs/play.svg';
import { ReactComponent as SvgSound } from 'svgs/sound.svg';
import { isMobileLayout } from 'utils/styles/responsive';

import ProgressLine from './ProgressLine/ProgressLine';

import * as S from './Player.styles';

const vimeoConfig = {
  vimeo: {
    playerOptions: {
      playsinline: true,
    },
  },
};

export interface PlayerProps {
  url: string;
  onPlay?: () => void;
  playing?: boolean;
  controls?: boolean;
  playIcon?: boolean;
  muted?: boolean;
}

const defaultProps: Partial<PlayerProps> = {
  url: '',
  playing: false,
  controls: true,
  playIcon: false,
  muted: true,
};

const Player: FunctionComponent<PlayerProps> = ({
  url,
  onPlay,
  playing,
  controls,
  playIcon,
  muted,
  ...rest
}) => {
  const [videoMuted, setVideoMuted] = useState<boolean>(muted);
  const [isPlaying, setIsPlaying] = useState(playing);

  const playerRef = useRef(null);

  const { copy } = useCopyStore();

  const handleClick = (e: KeyboardEvent) =>
    e.key === ' ' && setIsPlaying(!isPlaying);

  useEffect(() => {
    document.body.addEventListener('keydown', handleClick);

    return () => document.body.removeEventListener('keydown', handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const soundButton = (
    <S.SoundButtonWrapper tabIndex={0} isMobileLayout={isMobileLayout()}>
      {videoMuted ? (
        <SvgMute onClick={() => setVideoMuted(false)} />
      ) : (
        <SvgSound onClick={() => setVideoMuted(true)} />
      )}
    </S.SoundButtonWrapper>
  );

  return (
    <S.Wrapper {...rest}>
      {soundButton}
      <S.ClickPoint onClick={() => setIsPlaying(!isPlaying)} />
      <ReactPlayer
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        controls={controls}
        playing={isPlaying}
        playsinline
        config={vimeoConfig}
        onReady={onPlay}
        volume={playing ? +!videoMuted : 0}
      />

      <ProgressLine
        playerRef={playerRef}
        playing={playing}
        isPlaying={isPlaying}
      />

      {playIcon && !isPlaying && (
        <S.IconWrapper aria-label={copy.global.accessibility.ctaPlay}>
          <SvgPlay />
        </S.IconWrapper>
      )}
    </S.Wrapper>
  );
};

Player.defaultProps = defaultProps;

export default memo(Player);
