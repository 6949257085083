import { kebabCase } from 'lodash';

import { Pages } from 'utils/routes';

import { CopyStoreType } from './copy.types';
import aboutPageEN from './translations/en/aboutPage.json';
import contactPageEN from './translations/en/contactPage.json';
import footerEN from './translations/en/footer.json';
import globalEN from './translations/en/global.json';
import headEN from './translations/en/head.json';
import headerEN from './translations/en/header.json';
import indexPageEN from './translations/en/indexPage.json';
import notFoundPageEN from './translations/en/notFoundPage.json';
import privacyPageEN from './translations/en/privacyPage.json';
import processPageEN from './translations/en/processPage.json';
import projectsEN from './translations/en/projects.json';
import servicesPageEN from './translations/en/servicesPage.json';

const TRANSLATIONS_PATH = './translations';

const tryRequire = (path: string) => {
  try {
    return require(`${path}`);
  } catch (error) {
    if (process.env.IS_DEBUG)
      console.log('copy.data -- tryRequire -- error:', error);

    return null;
  }
};

export const getCopy = (page: Pages, locale = process.env.DEFAULT_LOCALE) => {
  const path = `${TRANSLATIONS_PATH}/${kebabCase(locale)}`;
  const head: Record<typeof page, CopyStoreType['copy']['head']> =
    tryRequire(`${path}/head.json`) ||
    (headEN as Record<
      typeof page,
      Pick<CopyStoreType['copy']['head'], 'title' | 'description' | 'ogImage'>
    >);

  return {
    head: {
      ...head[page],
      ogType: 'website',
    },
    global:
      tryRequire(`${path}/global.json`) ||
      (globalEN as CopyStoreType['copy']['global']),
    header:
      tryRequire(`${path}/header.json`) ||
      (headerEN as CopyStoreType['copy']['header']),
    index:
      tryRequire(`${path}/indexPage.json`) ||
      (indexPageEN as CopyStoreType['copy']['index']),
    about:
      tryRequire(`${path}/aboutPage.json`) ||
      (aboutPageEN as unknown as CopyStoreType['copy']['about']),
    contact:
      tryRequire(`${path}/contactPage.json`) ||
      (contactPageEN as CopyStoreType['copy']['contact']),
    services:
      tryRequire(`${path}/servicesPage.json`) ||
      (servicesPageEN as CopyStoreType['copy']['services']),
    process:
      tryRequire(`${path}/processPage.json`) ||
      (processPageEN as CopyStoreType['copy']['process']),
    privacy:
      tryRequire(`${path}/privacyPage.json`) ||
      (privacyPageEN as CopyStoreType['copy']['privacy']),
    footer:
      tryRequire(`${path}/footer.json`) ||
      (footerEN as CopyStoreType['copy']['footer']),
    projects:
      tryRequire(`${path}/projects.json`) ||
      (projectsEN as CopyStoreType['copy']['projects']),
    notFound:
      tryRequire(`${path}/notFoundPage.json`) ||
      (notFoundPageEN as CopyStoreType['copy']['notFound']),
  } as CopyStoreType['copy'];
};
