import { useRouter } from 'next/router';
import React, { Fragment, FunctionComponent } from 'react';

import Text from 'components/Text/Text';
import { useCopyStore } from 'store';
import { LocationProps } from 'store/copy.types';
import { ROUTES } from 'utils/routes';
import { fadeInUpMotion } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as S from './Locations.styles';

export interface LocationDefaultProps {
  white?: boolean;
}

const defaultProps: Partial<LocationDefaultProps> = {
  white: false,
};

const Locations: FunctionComponent<LocationDefaultProps> = ({ white }) => {
  const { copy } = useCopyStore();
  const { pathname } = useRouter();
  const isContactPage = pathname === ROUTES.CONTACT;

  const doubleColumnParser = (locations: any[]) => {
    if (!locations || !locations.length) return [];

    const arr = [];

    for (let x = 0; x <= locations.length - 1; x++) {
      const innerArr = [];

      innerArr.push(locations[x]);
      if (x + 1 < locations.length) {
        if (locations[x + 1].city.includes(locations[x].city)) {
          innerArr.push(locations[x + 1]);
          x += 1;
        }
      }

      arr.push(innerArr);
    }

    return arr;
  };

  return (
    <S.Wrapper white={white}>
      {doubleColumnParser(copy.footer.locations).map((obj, index) => (
        <S.SingleLocation
          key={index}
          {...fadeInUpMotion}
          transition={{ delay: 0.2 * (index + 1), duration: 0.6 }}
        >
          {obj
            .slice(0, 2)
            .map(
              (
                {
                  city,
                  person,
                  jobTitle,
                  phone,
                  address,
                  email,
                }: LocationProps,
                index: number
              ) => (
                <Fragment key={index}>
                  {city && (
                    <S.City
                      isContactPage={isContactPage}
                      style={{ gridArea: 'city' }}
                    >
                      <Text
                        as="p"
                        text={city ? city : '&nbsp;'}
                        size="heading6"
                        color={white ? ColorNames.white : ColorNames.swamp}
                      />
                    </S.City>
                  )}

                  <S.Container style={{ gridArea: `contact${index}` }}>
                    <S.ContactPerson>
                      <Text
                        as="p"
                        text={person}
                        size="body2"
                        color={white ? ColorNames.white : ColorNames.swamp}
                      />

                      <Text
                        as="p"
                        text={jobTitle}
                        size="body2"
                        color={white ? ColorNames.white : ColorNames.swamp}
                      />
                    </S.ContactPerson>

                    <S.Address>
                      <Text
                        as="p"
                        text={`${phone}<br/>${
                          email
                            ? `<a href='mailto:${email}'>${email}</a><br/>`
                            : ''
                        }${address}`}
                        size="body2"
                        color={white ? ColorNames.white : ColorNames.swamp}
                      />
                    </S.Address>
                  </S.Container>
                </Fragment>
              )
            )}
        </S.SingleLocation>
      ))}
    </S.Wrapper>
  );
};

Locations.defaultProps = defaultProps;

export default Locations;
