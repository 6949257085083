import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface MenuWrapperProps {
  isOpen: boolean;
}

interface AnchorProps {
  isActive: boolean;
  isHome: boolean;
}

export const Wrapper = styled(motion.header)`
  max-width: 1920px;
  height: 239rem;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 46rem 96rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 1;
`;

export const MenuButtonWrapper = styled(motion.div)`
  position: absolute;
  right: 82rem;
  top: 112rem;
  z-index: 2;
  display: block;

  ${mediaTablet(`
    display: none;
  `)}
`;

export const MenuWrapper = styled(motion.nav)<MenuWrapperProps>`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 22rem;
  padding: 55rem;
  background: ${colors.swampLighter};
  transform: translateX(${({ isOpen }) => (isOpen ? '0%' : '-100%')});
  transition-duration: 0.3s;
  transition-property: all;

  ${mediaTablet(`
    position: relative;
    align-items: center;
    flex-direction: row;
    padding: 40rem 0 0 0;
    gap: 86rem;
    background: transparent;
    transform: translateX(0%);
  `)}
`;

export const FooterMenuWrapper = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: 135rem;
  left: 0;
  display: none;
  justify-content: flex-end;
  padding: 55rem;

  a {
    color: ${colors.swamp};
  }

  ${mediaTablet(`
    display: none;
  `)}
`;

export const Anchor = styled(motion.a)<AnchorProps>`
  ${setTypography('bodyBoldSmall')};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.6s;
  transition-property: color;

  ${({ isHome, isActive }) =>
    isHome
      ? `
    color: ${colors.white};
    &:hover {
      color: ${colors.alizarinCrimson};
    }
  `
      : `
    color: ${isActive ? colors.alizarinCrimson : colors.white};
    &:hover {
      color: ${colors.alizarinCrimson};
    }

    ${mediaTablet(`
      color: ${isActive ? colors.alizarinCrimson : colors.white};
    `)}
  `};
`;

export const LogoWrapper = styled(motion.div)`
  width: 298rem;
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  left: 82rem;
  top: 75rem;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: ${colors.white};
  }

  ${mediaTablet(`
    position: relative;
    margin-right: 96rem;
    right: 0;
    top: 0;
  `)}
`;
