import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';

import OvalButton from 'components/OvalButton/OvalButton';
import Text from 'components/Text/Text';
import { useCopyStore } from 'store';
import Analytics from 'utils/analytics';
import { ColorNames } from 'utils/styles/theme';

import * as S from './CookieBanner.styles';

export interface CookieBannerProps {}

const defaultProps: Partial<CookieBannerProps> = {};

const CookieBanner: React.FC<CookieBannerProps> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const { copy } = useCopyStore();

  const checkCookies = () => {
    const cookies = Cookies.get('human-dna.cookiesConsent');
    setIsVisible(!cookies);
  };

  const handleOnClick = ({ target }) => {
    const isAcceptButton = Boolean(
      target.innerText === copy.global.cookies.ctaAccept
    );

    Cookies.set('human-dna.cookiesConsent', isAcceptButton.toString(), {
      expires: 365,
    });

    if (isAcceptButton) new Analytics()._initializeTracker();
    setIsVisible(false);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(checkCookies, []);

  if (!isMounted || !isVisible) return null;

  return ReactDom.createPortal(
    <S.CookieBannerWrapper role="banner">
      <S.Container aria-label={copy.global.cookies.title}>
        <Text as="p" text={copy.global.cookies.title} size="button" />

        <S.ButtonsWrapper>
          <OvalButton
            onClick={handleOnClick}
            text={copy.global.cookies.ctaDiscard}
            color={ColorNames.white}
            backgroundColor={ColorNames.tallPoppy}
            tabIndex={1}
          />
          <OvalButton
            onClick={handleOnClick}
            text={copy.global.cookies.ctaAccept}
            tabIndex={2}
          />
        </S.ButtonsWrapper>
      </S.Container>
    </S.CookieBannerWrapper>,
    document.body
  );
};

CookieBanner.defaultProps = defaultProps;

export default CookieBanner;
