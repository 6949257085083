import React, {
  FunctionComponent,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player/lazy';

import { useGlobalStore } from 'store';
import { colors } from 'utils/styles/theme';

import { handleMovePointer } from '../Player.utils';

import * as S from './ProgressLine.styles';

const CHART_WIDTH = 906;

export interface PlayerProps {
  playing?: boolean;
  isPlaying?: boolean;
  playerRef: MutableRefObject<ReactPlayer>;
}

const defaultProps: Partial<PlayerProps> = {
  playing: false,
};

const ProgressLine: FunctionComponent<PlayerProps> = ({
  playing,
  isPlaying,
  playerRef,
  ...rest
}) => {
  const { setSwipe } = useGlobalStore();

  const [playerTime, setPlayerTime] = useState({
    pointer: 0,
    progress: 0,
  });
  const [isMovePointer, setIsMovePointer] = useState<boolean>(false);

  const lineRef = useRef(null);

  const calcPlayerTime = useCallback((totalTime = 0, currentTime = 0) => {
    const calculatedPointer = currentTime / totalTime;

    return {
      pointer: Number((calculatedPointer * CHART_WIDTH).toFixed()),
      progress: Number(calculatedPointer * 100),
    };
  }, []);

  useEffect(() => {
    let interval;

    if (playerRef?.current && playing) {
      interval = setInterval(() => {
        setPlayerTime(
          calcPlayerTime(
            playerRef?.current?.getDuration(),
            playerRef?.current?.getCurrentTime()
          )
        );
      }, 100);

      if (!isPlaying) clearInterval(interval);

      return () => clearInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, isPlaying, playing]);

  const containerVariants = {
    initial: { scaleX: 0 },
    animate: { scaleX: playerTime.progress / 100, originX: 0 },
  };

  const handlePointer = e => {
    handleMovePointer(e, playerRef, lineRef);
    setPlayerTime(
      calcPlayerTime(
        playerRef?.current?.getDuration(),
        playerRef?.current?.getCurrentTime()
      )
    );
  };

  return (
    <S.Wrapper {...rest}>
      <S.Container ref={lineRef}>
        <S.SeekBar
          variants={containerVariants}
          initial="initial"
          animate="animate"
          transition={{ type: 'spring', duration: 0 }}
        />
        <S.UnderSeekBar />
      </S.Container>
      <S.Pointer
        viewBox={`0 0 ${CHART_WIDTH + 10} 14`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handlePointer}
        onPointerDown={() => (setIsMovePointer(true), setSwipe(false))}
        onPointerUp={() => (setIsMovePointer(false), setSwipe(true))}
        onPointerMove={e => isMovePointer && handlePointer(e)}
      >
        <ellipse
          cx={playerTime.pointer + 6 || 6}
          cy="6.24163"
          rx="6.68266"
          ry="6.24163"
          fill={colors.alizarinCrimson}
          style={{ cursor: 'pointer' }}
        />
      </S.Pointer>
    </S.Wrapper>
  );
};

ProgressLine.defaultProps = defaultProps;

export default memo(ProgressLine);
