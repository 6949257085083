export enum ColorNames {
  white = 'white',
  black = 'black',
  swamp = 'swamp',
  enargyYellow = 'enargyYellow',
  alizarinCrimson = 'alizarinCrimson',
  gallery = 'gallery',
  monaLisa = 'monaLisa',
  alabaster = 'alabaster',
  silver = 'silver',
  tallPoppy = 'tallPoppy',
  outerSpace = 'outerSpace',
  swampLighter = 'swampLighter',
  alabasterLight = 'alabasterLight',
}

export enum FontNames {
  urbanist = 'urbanist',
}

export const validFontCategories = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'heading6',
  'body1',
  'body2',
  'body3',
  'body4',
  'bodyTextLarge',
  'bodyBoldSmall',
  'bodyBoldMedium',
  'bodyBoldBig',
  'bodyExtraBoldSmall',
  'stepsTitle',
  'stepsDescription',
  'titleMedium',
  'paragraphMedium',
  'paragraphLower',
  'button',
  'scrollButton',
  'labelSmall',
  'linkSmall',
  'notFound',
  'modalSubtitle',
  'modalTitle',
  'modalBody',
] as const;

interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#fff',
  [ColorNames.black]: '#000',
  [ColorNames.swamp]: '#001625',
  [ColorNames.enargyYellow]: '#F9DE4B',
  [ColorNames.alizarinCrimson]: '#DC2424',
  [ColorNames.gallery]: '#F0F0F0',
  [ColorNames.monaLisa]: '#FF9D9D',
  [ColorNames.alabaster]: '#F8F8F8',
  [ColorNames.silver]: '#C8C8C8',
  [ColorNames.tallPoppy]: '#B12A2A',
  [ColorNames.outerSpace]: '#2A3338',
  [ColorNames.swampLighter]: '#000B12',
  [ColorNames.alabasterLight]: '#FAFCFC',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.urbanist]:
      // eslint-disable-next-line quotes
      "'Urbanist', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: rem, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    heading1: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 292, mobile: 219 },
      lineHeight: { tablet: 0.72, mobile: 0.84678 },
      fontWeight: 700,
      letterSpacing: -0.01,
      textTransform: 'lowercase',
    },
    heading2: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 152, mobile: 110 },
      lineHeight: { tablet: 1.127, mobile: 0.85 },
      fontWeight: 700,
      letterSpacing: -0.01,
    },
    heading3: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 311, mobile: 150 },
      lineHeight: { tablet: 0.85, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: -0.01,
    },
    heading4: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 71, mobile: 110 },
      lineHeight: { tablet: 1.53, mobile: 1.19 },
      fontWeight: 600,
    },
    heading5: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 113, mobile: 96 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: -0.01,
    },
    heading6: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 71, mobile: 110 },
      lineHeight: { tablet: 1.362, mobile: 1.3587 },
      fontWeight: 700,
      letterSpacing: -0.04,
    },
    body1: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 40, mobile: 60 },
      lineHeight: { tablet: 1.93, mobile: 1.83 },
      fontWeight: 400,
      letterSpacing: 0.012,
    },
    body2: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 35, mobile: 50 },
      lineHeight: { tablet: 1.46, mobile: 1.345 },
      fontWeight: 400,
      letterSpacing: -0.04,
    },
    body3: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 75, mobile: 55 },
      lineHeight: { tablet: 1.06, mobile: 1.3 },
      fontWeight: 700,
    },
    body4: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 50, mobile: 60 },
      lineHeight: { tablet: 2.171, mobile: 0.98 },
      fontWeight: 600,
    },
    bodyTextLarge: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 42.353, mobile: 65.4545 },
      lineHeight: { tablet: 1.875, mobile: 1.875 },
      fontWeight: 400,
    },
    bodyBoldSmall: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 33, mobile: 219 },
      lineHeight: { tablet: 1.0, mobile: 1.2454 },
      fontWeight: 600,
    },
    bodyExtraBoldSmall: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 33, mobile: 53 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
    },
    bodyBoldMedium: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 50, mobile: 70 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
    },
    bodyBoldBig: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 389, mobile: 219 },
      lineHeight: { tablet: 0.85, mobile: 0.85 },
      fontWeight: 600,
    },
    stepsTitle: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 311, mobile: 164 },
      lineHeight: { tablet: 1.0, mobile: 0.85 },
      fontWeight: 700,
      letterSpacing: -0.01,
    },
    stepsDescription: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 64, mobile: 82 },
      lineHeight: { tablet: 1.29, mobile: 1.2 },
      fontWeight: 600,
    },
    titleMedium: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 48, mobile: 60 },
      lineHeight: { tablet: 0.97, mobile: 0.979 },
      fontWeight: 600,
      letterSpacing: 0.05,
    },
    paragraphMedium: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 128, mobile: 110 },
      lineHeight: { tablet: 0.973, mobile: 1.2 },
      fontWeight: 600,
    },
    paragraphLower: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 40, mobile: 34.547 },
      lineHeight: { tablet: 0.973, mobile: 1.2 },
      fontWeight: 600,
    },
    button: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 23, mobile: 44 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: -0.03,
    },
    scrollButton: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 36, mobile: 36 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 600,
    },
    labelSmall: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 23, mobile: 37 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: -0.03,
    },
    linkSmall: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 22, mobile: 50 },
      lineHeight: { tablet: 1.0, mobile: 1.2 },
      fontWeight: 700,
      letterSpacing: -0.04,
    },
    notFound: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 529.412, mobile: 409.09 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: 0.02,
    },
    modalSubtitle: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 36, mobile: 56 },
      lineHeight: { tablet: 1.0, mobile: 1.0 },
      fontWeight: 400,
      letterSpacing: 0.02,
    },
    modalTitle: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 71, mobile: 66 },
      lineHeight: { tablet: 0.819, mobile: 1.0 },
      fontWeight: 700,
      letterSpacing: 0.02,
    },
    modalBody: {
      fontFamily: { default: FontNames.urbanist },
      fontSize: { tablet: 32, mobile: 40 },
      lineHeight: { tablet: 1.514, mobile: 1.6 },
      fontWeight: 400,
      letterSpacing: 0.02,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 40, // rem
  lineOpacity: 0.1,
  zIndex: {
    languageSelector: 2,
    nonFunctionals: 3,
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;
