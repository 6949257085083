import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const CookieBannerWrapper = styled(motion.div)`
  width: fit-content;
  min-width: 900rem;
  position: fixed;
  bottom: 95px;
  left: 0;
  right: 0;
  margin: 0 82rem;
  margin: 0 auto;
  background: ${colors.tallPoppy};
  border-radius: 8px;

  ${mediaTablet(`
    bottom: 20px;
  `)}
`;

export const Container = styled.div`
  display: grid;
  place-items: center;
  padding: 55rem 77rem;
  text-align: center;

  p {
    font-size: 16px;
    width: calc(100% - calc(180rem - 77rem));
    margin: 0 calc(180rem - 77rem) 52rem;
  }

  ${mediaTablet(`
    width: auto;
    padding: 27rem 53rem;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    place-items: left;

    p {
        width: calc(100% - calc(180rem - 53rem));
        margin: 0 calc(180rem - 53rem) 0 0;
      }
  `)}
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 29rem;
`;
