import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)<{ darkBackground?: boolean }>`
  width: 100vw;
  min-height: ${setVh(100)};
  height: 100%;
  padding-top: 420rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaTablet(`
    padding-top: 239rem;
  `)}
`;

export const Content = styled(motion.main)`
  width: 100%;
  max-width: 1920px;
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const BottomBar = styled(motion.div)`
  width: 100%;
  height: 279rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 55rem;
  background: ${colors.tallPoppy};

  & > div {
    width: 100%;
  }

  ${mediaTablet(`
    height: 159rem;
    padding: 0 102rem;
  `)}
`;
