import { AnimatePresence } from 'framer-motion';
import { GetStaticProps } from 'next';
import { Router } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { useCopyStore } from 'store';
import { CopyStoreType } from 'store/copy.types';
import { hasWebGl, isStorybook, isSupportedBrowser } from 'u9/utils/platform';
import { queryCMS } from 'utils/cms/api';
import { notFoundPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { ROUTES } from 'utils/routes';

import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import NotFound from './NotFound/NotFound';
import SocialBrowserUnsupported from './SocialBrowserUnsupported/SocialBrowserUnsupported';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';
import WebGLDisabled from './WebGLDisabled/WebGLDisabled';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const result = await queryCMS({
    query: notFoundPageQuery({ locale }),
  });

  const { header, sharedCopy, notFoundPage: page } = result;
  const { head, title, linkText } = page;
  const { menu } = header;

  return {
    props: {
      initialCopy: {
        head,
        header: {
          menu,
        },
        global: sharedCopy.global,
        notFound: {
          title,
          linkText,
        },
      },
    },
    revalidate: ISR_TIMEOUT,
  };
};

interface NonFunctionalsProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    header: CopyStoreType['copy']['header'];
    global: CopyStoreType['copy']['global'];
    notFound: CopyStoreType['copy']['notFound'];
  };
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  router: Router;
}

const NonFunctionals: React.FunctionComponent<NonFunctionalsProps> = ({
  initialCopy,
  children,
  isBypassed = false,
  router,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [isSubStandardBrowserAccepted, setSubstandardBrowserAccepted] =
    useState(false);
  const [hasNoWebGl, setNoWebGl] = useState(false);
  const [showUnsupportedSocial, setUnsupportedSocial] = useState(false);

  const hasMatchingRoute = useMemo(
    () =>
      Object.values(ROUTES).includes(
        router.route as typeof ROUTES[keyof typeof ROUTES]
      ) && router.route !== ROUTES.NOT_FOUND,
    [router.route]
  );

  const { setCopy } = useCopyStore();

  const errorComponent = useMemo(() => {
    if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
      return <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} />;
    } else if (showUnsupportedSocial && !isSubStandardBrowserAccepted) {
      return (
        <SocialBrowserUnsupported setAccept={setSubstandardBrowserAccepted} />
      );
    } else if (!hasMatchingRoute) {
      return <NotFound />;
    } else if (hasNoWebGl) {
      return <WebGLDisabled />;
    }

    return null;
  }, [
    hasMatchingRoute,
    hasNoWebGl,
    isSubStandardBrowserAccepted,
    showUnsupportedBrowser,
    showUnsupportedSocial,
  ]);

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, fromSocial } = isSupportedBrowser();

      if (!isSupported && !fromSocial) {
        setShowUnsupportedBrowser(true);
      } else if (fromSocial) {
        setUnsupportedSocial(true);
      }

      setNoWebGl(!hasWebGl());
    }
  }, [isMounted]);

  useEffect(() => {
    if (isStorybook()) return;
    const { head, global } = initialCopy;

    // If this is an error page, set the shared copy
    if (errorComponent) {
      setCopy({ global });
    }

    // If this is the 404 page, update the head copy
    if (!hasMatchingRoute) {
      setCopy({ head });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorComponent, hasMatchingRoute]);

  if (isBypassed) return <>{children}</>;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {errorComponent || children}
      </AnimatePresence>
      {!isRendered && (
        <noscript>
          <JavaScriptDisabled sharedCopy={{ global: initialCopy.global }} />
        </noscript>
      )}
    </>
  );
};

export default React.memo(NonFunctionals);
