import create, { UseBoundStore } from 'zustand';

import { ProjectProps } from './copy.types';

export interface GlobalStoreType {
  isWindowTooSmall: boolean;
  setWindowTooSmall: (state: boolean) => void;

  swipe: boolean;
  setSwipe: (state: boolean) => void;

  shouldRotateDevice: boolean;
  setRotateDevice: (state: boolean) => void;

  selectedProject: ProjectProps;
  setSelectedProject: (state: ProjectProps) => void;

  filteredItems: ProjectProps[];
  setFilteredItems: (state: ProjectProps[]) => void;

  isModalVisible: boolean;
  setModalVisible: (state: boolean) => void;

  videoOnPlay: boolean;
  setVideoOnPlay: (state: boolean) => void;

  rotateDeviceOpen: boolean;
  setRotateDeviceOpen: (state: boolean) => void;
}

const useGlobalStore: UseBoundStore<GlobalStoreType> = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      isWindowTooSmall: false,
      setWindowTooSmall: state => {
        set(() => ({
          isWindowTooSmall: state,
        }));
      },

      swipe: true,
      setSwipe: state => {
        set(() => ({
          swipe: state,
        }));
      },

      shouldRotateDevice: false,
      setRotateDevice: state => {
        set(() => ({
          shouldRotateDevice: state,
        }));
      },

      selectedProject: null,
      setSelectedProject: state => {
        set(() => ({
          selectedProject: state,
        }));
      },

      filteredItems: [],
      setFilteredItems: state => {
        set(() => ({
          filteredItems: state,
        }));
      },

      isModalVisible: false,
      setModalVisible: state => {
        set(() => ({
          isModalVisible: state,
        }));
      },

      videoOnPlay: false,
      setVideoOnPlay: state => {
        set(() => ({
          videoOnPlay: state,
        }));
      },

      rotateDeviceOpen: false,
      setRotateDeviceOpen: state => {
        set(() => ({
          rotateDeviceOpen: state,
        }));
      },
    };
  }
);

export default useGlobalStore;
