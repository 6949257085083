import { motion, Transition } from 'framer-motion';
import React, { FunctionComponent } from 'react';

import { colors } from 'utils/styles/theme';

interface MenuButtonProps {
  width?: number;
  height?: number;
  isOpen?: boolean;
  color?: string;
  strokeWidth?: string | number;
  transition?: Transition;
  lineProps?: any;
  onClick: () => void;
}

const defaultProps: Partial<MenuButtonProps> = {
  width: 35,
  height: 24,
};

const MenuButton: FunctionComponent<MenuButtonProps> = ({
  isOpen = false,
  width = 35,
  height = 24,
  strokeWidth = 3,
  color = colors.white,
  transition = null,
  lineProps = null,
  ...props
}) => {
  const variant = isOpen ? 'opened' : 'closed';
  const top = {
    closed: {
      translateY: 0,
    },
    opened: {
      translateY: 1.5,
    },
  };
  const bottom = {
    closed: {
      translateY: 0,
    },
    opened: {
      translateY: -1.5,
    },
  };
  lineProps = {
    stroke: color,
    strokeWidth: strokeWidth as number,
    vectorEffect: 'non-scaling-stroke',
    initial: 'closed',
    animate: variant,
    transition,
    ...lineProps,
  };
  const unitHeight = 5;
  const unitWidth = (unitHeight * (width as number)) / (height as number);

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      whileHover={{ cursor: 'pointer' }}
      {...props}
    >
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="1"
        y2="1"
        variants={top}
        {...lineProps}
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="4"
        y2="4"
        variants={bottom}
        {...lineProps}
      />
    </motion.svg>
  );
};

MenuButton.defaultProps = defaultProps;

export default MenuButton;
