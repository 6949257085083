import { NextRouter } from 'next/router';

import { CopyStoreType } from 'store/copy.types';

interface KeyboardFocusTypes {
  isModalVisible: boolean;
  router: NextRouter;
  copy: CopyStoreType['copy'];
}

const keyboardRouteChange = (
  e: KeyboardEvent,
  copy: KeyboardFocusTypes['copy'],
  router: KeyboardFocusTypes['router']
) => {
  const menu = [{ link: '/' }, ...copy.header?.menu];
  const actualRouteIndex = menu.findIndex(
    ({ link }) => (link.includes('/') ? link : '/' + link) === router.route
  );

  const nextRouteIndex =
    actualRouteIndex + 1 > menu.length - 1 ? 0 : actualRouteIndex + 1;

  const prevRouteIndex =
    actualRouteIndex - 1 < 0 ? menu.length - 1 : actualRouteIndex - 1;

  if (e.key === 'ArrowLeft') router.push(menu[prevRouteIndex].link);
  if (e.key === 'ArrowRight') router.push(menu[nextRouteIndex].link);
};

export const keyboardFocus = (
  e: KeyboardEvent,
  { isModalVisible, router, copy }: KeyboardFocusTypes
) => {
  const documentClassList = document.documentElement.classList;
  const className = 'accessibility-focus';

  if (e.key === 'Tab') documentClassList.add(className);

  if (documentClassList.contains(className))
    documentClassList.remove(className);

  if (!isModalVisible) keyboardRouteChange(e, copy, router);
};
