export const distanceBetweenDivs = (elementOne, elementTwo) => {
  const el1 = elementOne.getBoundingClientRect();
  const el2 = elementTwo.getBoundingClientRect();

  return el1.left - el2.left;
};

export const handleMovePointer = (
  { clientX, currentTarget },
  playerRef,
  lineRef
) => {
  const duration = playerRef.current.getDuration();
  const lineWidth = lineRef.current.parentElement.clientWidth;

  const calc =
    clientX -
    currentTarget.getBoundingClientRect().x -
    distanceBetweenDivs(lineRef.current, currentTarget);

  const percent =
    (calc /
      (lineWidth - 2 * distanceBetweenDivs(lineRef.current, currentTarget))) *
    100;
  const time = (percent / 100) * duration;

  playerRef.current.seekTo(
    time <= 0 ? 0 : time >= duration ? duration : time,
    'seconds'
  );
};
