import React, { useRef } from 'react';
import styled from 'styled-components';

import { useEffectOnce } from 'hooks/useEffectOnce';

import App from './classes/App';
import { appState } from './index.state';

const Wrapper = styled.div`
  z-index: -9999;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;

  canvas {
    width: 100vw;
    height: 100vh;
  }
`;

const Background = () => {
  const rendererEl = useRef<HTMLDivElement | null>(null);
  useEffectOnce(() => {
    appState.app = new App({ rendererEl: rendererEl.current });
  });
  return <Wrapper ref={rendererEl}></Wrapper>;
};

export default Background;
