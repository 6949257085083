import React, { FunctionComponent } from 'react';

import { useSvgIcon } from 'u9/hooks';

import * as S from './Button.styles';

export const validIcons = ['soundcloud', 'instagram'] as const;

export interface ButtonProps {
  link: string;
  icon?: '' | string | typeof validIcons[number];
  label?: string;
}

const defaultProps: Partial<ButtonProps> = {
  icon: '',
};

const Button: FunctionComponent<ButtonProps> = ({ link, label, icon }) => {
  const { SvgIcon } = useSvgIcon(icon);

  return (
    <S.Wrapper withIcon={!!icon}>
      <a href={link} target="_blank" rel="noreferrer">
        {icon && <SvgIcon />}
        {label && <span>{label}</span>}
      </a>
    </S.Wrapper>
  );
};

Button.defaultProps = defaultProps;

export default Button;
