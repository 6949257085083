import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40rem;

  ${mediaTablet(`
    gap: 25rem;
  `)}
`;

export const MenuItem = styled(motion.div)`
  color: ${colors.white};

  span,
  a {
    ${setTypography('linkSmall')};
  }

  a {
    cursor: pointer;
  }
`;
