import { AnimatePresence, useAnimation, useMotionValue } from 'framer-motion';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import { v4 } from 'uuid';

import Player from 'components/Player/Player';
import Text from 'components/Text/Text';
import useDisableScroll from 'hooks/useDisableScroll';
import useSlides from 'hooks/useSlides';
import { useCopyStore, useGlobalStore } from 'store';
import { ReactComponent as SvgArrow } from 'svgs/arrow.svg';
import { ReactComponent as SvgClose } from 'svgs/close.svg';
import { ReactComponent as SvgCloseMobile } from 'svgs/closeMobile.svg';
import { ReactComponent as SvgInfo } from 'svgs/info.svg';
import { ReactComponent as SvgPlay } from 'svgs/play.svg';
import useWindowSize from 'u9/hooks/useWindowSize';
import { ROUTES } from 'utils/routes';
import { fadeInUpMotion, opacityMotion } from 'utils/styles/animations';
import { isMobileLayout } from 'utils/styles/responsive';
import { breakpointDesktop, breakpointTablet } from 'utils/styles/vars';

import * as S from './Modal.styles';

export interface ModalProps {}

const defaultProps: Partial<ModalProps> = {};

const Modal: FunctionComponent<ModalProps> = () => {
  const {
    swipe,
    filteredItems: filteredProjects,
    selectedProject,
    isModalVisible,
    videoOnPlay,
    setSelectedProject,
    setModalVisible,
    setVideoOnPlay,
    rotateDeviceOpen,
  } = useGlobalStore();
  const { copy } = useCopyStore();
  const { width, height, isLandscape } = useWindowSize(true);
  const { previews, prev, next } = useSlides();

  useDisableScroll(isModalVisible);

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpenMobileInfo, setIsOpenMobileInfo] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState(-1);

  const router = useRouter();
  const outerBoxRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<any>(null);

  const filteredItems =
    router.pathname === ROUTES.SERVICES ? filteredProjects : copy.projects;

  const animateMobileInfoBar = useAnimation();
  const x = useMotionValue(0);

  const settings = {
    initialSlide: imageIndex,
    infinite: false,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    arrows: false,
    swipe: swipe,
    accessibility: true,
    beforeChange: (current, next) => setImageIndex(next),
  };

  useEffect(() => {
    setIsMobile(
      width < breakpointTablet ||
        (isLandscape &&
          width < breakpointDesktop &&
          height < breakpointTablet) ||
        isMobileLayout()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, isLandscape]);

  useEffect(() => {
    isModalVisible && !isMobile && hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, isMobile]);

  useEffect(() => {
    return () => {
      if (sliderRef.current) sliderRef.current.slickPause();
    };
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      const initialSlideIndex = filteredItems.findIndex(
        project => project.id === selectedProject.id
      );
      setImageIndex(initialSlideIndex);
      sliderRef.current?.slickGoTo(initialSlideIndex);
    } else setImageIndex(-1);
  }, [isModalVisible, sliderRef]);

  const hide = useCallback(() => {
    setModalVisible(false);
    setSelectedProject(null);
  }, []);

  const handleTitleClick = useCallback(() => {
    if (selectedProject?.case_study?.id) {
      router.push({
        pathname: ROUTES.CASE_STUDY,
        query: { id: selectedProject?.case_study.id },
      });
    }
  }, [selectedProject, router]);

  const handleClick = (e: KeyboardEvent) => {
    e.key === 'Escape' && hide();

    if (e.key === 'ArrowLeft' && previews.prev) {
      sliderRef.current?.slickGoTo(imageIndex - 1);
    }

    if (e.key === 'ArrowRight' && previews.next) {
      sliderRef.current?.slickGoTo(imageIndex + 1);
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleClick);

    if (!isModalVisible)
      document.body.removeEventListener('keydown', handleClick);

    return () => document.body.removeEventListener('keydown', handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, previews, imageIndex]);

  const mobileCloseButton = (
    <>
      <S.SvgWrapper
        tabIndex={0}
        initial={{ opacity: 1 }}
        animate={
          isOpenMobileInfo
            ? { opacity: 0, visibility: 'hidden' }
            : { opacity: 1 }
        }
      >
        <SvgInfo
          onClick={() => {
            setIsOpenMobileInfo(true);
            animateMobileInfoBar.start({
              x: '-100%',
            });
          }}
        />
      </S.SvgWrapper>
      <SvgCloseMobile
        tabIndex={0}
        onClick={() => {
          if (x.get().toString() === '-100%') {
            animateMobileInfoBar.start({
              x: '0%',
            });
            setIsOpenMobileInfo(false);
          } else hide();
        }}
      />
    </>
  );

  const closeButton = (
    <S.CloseButtonWrapper
      tabIndex={0}
      aria-label={copy.global.accessibility?.ctaExitModal}
      isMobile={isMobile}
      initial="initial"
      animate={isModalVisible ? 'animate' : 'initial'}
      transition={
        isModalVisible
          ? { delay: 0.6, duration: 0.6 }
          : { delay: 0, duration: 0 }
      }
      variants={opacityMotion}
    >
      {isMobile ? mobileCloseButton : <SvgClose onClick={hide} />}
    </S.CloseButtonWrapper>
  );

  const handlePlay = () => {
    !videoOnPlay && setVideoOnPlay(true);
  };

  return (
    <S.Wrapper
      role="application"
      aria-modal="true"
      initial={{ ...opacityMotion.initial, pointerEvents: 'none' }}
      animate={
        isModalVisible
          ? {
              ...opacityMotion.animate,
              pointerEvents: 'auto',
              visibility: 'visible',
            }
          : {
              ...opacityMotion.initial,
              pointerEvents: 'none',
              visibility: 'hidden',
            }
      }
      transition={{ delay: 0.2 }}
      aria-hidden={!isModalVisible}
    >
      <S.OuterBox
        ref={outerBoxRef}
        initial={{ scale: 0.5 }}
        animate={isModalVisible ? { scale: 1 } : { scale: 0.5 }}
        transition={{ duration: 0.5 }}
      >
        {closeButton}

        {!isMobile && (
          <S.CarouselWrapper>
            {imageIndex >= 0 && (
              <Slider ref={sliderRef} {...settings}>
                {filteredItems.map((project, idx) => (
                  <S.InnerBox
                    key={project.id}
                    className={
                      idx === imageIndex ? 'slide activeSlide' : 'slide'
                    }
                    onClick={() => {
                      if (
                        idx === imageIndex + 1 ||
                        (idx === 0 && imageIndex === filteredItems.length - 1)
                      ) {
                        sliderRef.current?.slickGoTo(imageIndex + 1);
                      }
                      if (
                        idx === imageIndex - 1 ||
                        (imageIndex === 0 && idx === filteredItems.length - 1)
                      ) {
                        sliderRef.current?.slickGoTo(imageIndex - 1);
                      }
                    }}
                  >
                    <S.PlayerWrapper
                      id="player-wrapper"
                      tabIndex={0}
                      initial="initial"
                      animate={
                        isModalVisible && project ? 'animate' : 'initial'
                      }
                      variants={opacityMotion}
                      transition={{ delay: 0.4 }}
                    >
                      {idx === imageIndex && (
                        <Player
                          url={project?.video?.url || ''}
                          controls={false}
                          playing={!rotateDeviceOpen && idx === imageIndex}
                          onPlay={handlePlay}
                          playIcon
                          muted={false}
                        />
                      )}

                      <AnimatePresence exitBeforeEnter>
                        {idx !== imageIndex && (
                          <S.PreviewWrapper>
                            <S.IconWrapper
                              leftSide={
                                (imageIndex > idx ||
                                  (imageIndex === 0 &&
                                    idx === filteredItems.length - 1)) &&
                                !(
                                  idx === 0 &&
                                  imageIndex === filteredItems.length - 1
                                )
                              }
                            >
                              <SvgPlay />
                            </S.IconWrapper>
                            {project?.poster?.url && (
                              <img
                                src={project.poster.url}
                                alt={project.poster.alternativeText}
                              />
                            )}
                          </S.PreviewWrapper>
                        )}
                      </AnimatePresence>
                    </S.PlayerWrapper>

                    {idx === imageIndex && (
                      <S.ContentWrapper>
                        <S.TitleWrapper
                          key={idx + '-title'}
                          initial="initial"
                          animate={
                            isModalVisible && project ? 'animate' : 'initial'
                          }
                          variants={fadeInUpMotion}
                        >
                          <Text
                            as="label"
                            text={project?.video?.client}
                            size="modalSubtitle"
                          />
                          <S.TextComp
                            tabIndex={0}
                            text={project?.video?.title}
                            size="modalTitle"
                            onClick={handleTitleClick}
                            onKeyPress={e =>
                              e.key === 'Enter' && handleTitleClick()
                            }
                          />
                        </S.TitleWrapper>

                        <S.DescriptionWrapper
                          key={idx}
                          initial="initial"
                          animate={
                            isModalVisible && project ? 'animate' : 'initial'
                          }
                          variants={fadeInUpMotion}
                        >
                          <Text
                            text={project?.video?.description}
                            size="modalBody"
                          />
                        </S.DescriptionWrapper>
                      </S.ContentWrapper>
                    )}
                  </S.InnerBox>
                ))}
                {/* Bugged React Slick package, so here is fix/hack */}
                <></>
                <></>
              </Slider>
            )}
          </S.CarouselWrapper>
        )}

        {isMobile && (
          <S.InnerBox>
            <S.PlayerWrapper
              id="player-wrapper"
              tabIndex={0}
              isMobile={isMobile}
              initial="initial"
              animate={
                isModalVisible && selectedProject ? 'animate' : 'initial'
              }
              variants={opacityMotion}
              transition={{ delay: 0.4 }}
            >
              {isModalVisible && (
                <Player
                  url={selectedProject?.video?.url || ''}
                  controls={false}
                  playing={!rotateDeviceOpen}
                  onPlay={handlePlay}
                  playIcon
                  muted={false}
                />
              )}
            </S.PlayerWrapper>

            <S.ContentWrapper
              isMobile={isMobile}
              initial={isMobile ? { x: '0%' } : { x: 0 }}
              animate={isMobile ? animateMobileInfoBar : { x: 0 }}
              transition={{ duration: 0.3 }}
              style={{ x }}
            >
              <S.TitleWrapper
                key={v4()}
                initial="initial"
                animate={
                  isModalVisible && selectedProject ? 'animate' : 'initial'
                }
                variants={fadeInUpMotion}
              >
                <Text
                  as="label"
                  text={selectedProject?.video?.client}
                  size="modalSubtitle"
                />
                <S.TextComp
                  tabIndex={0}
                  text={selectedProject?.video?.title}
                  size="modalTitle"
                  onClick={handleTitleClick}
                  onKeyPress={e => e.key === 'Enter' && handleTitleClick()}
                />
              </S.TitleWrapper>

              <S.DescriptionWrapper
                key={v4()}
                initial="initial"
                animate={
                  isModalVisible && selectedProject ? 'animate' : 'initial'
                }
                variants={fadeInUpMotion}
              >
                <Text
                  text={selectedProject?.video?.description}
                  size="modalBody"
                />
              </S.DescriptionWrapper>
            </S.ContentWrapper>
            {!isOpenMobileInfo && (
              <S.MobileArrowsWrapper>
                <SvgArrow onClick={prev} />
                <SvgArrow onClick={next} />
              </S.MobileArrowsWrapper>
            )}
          </S.InnerBox>
        )}
      </S.OuterBox>
    </S.Wrapper>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
