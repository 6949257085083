export const ROUTES = {
  HOME: '/',
  CASE_STUDY: '/case-study/[id]',
  ABOUT: '/about',
  CONTACT: '/contact',
  SERVICES: '/services',
  PROCESS: '/process',
  PRIVACY: '/privacy',
  NOT_FOUND: '/404',
} as const;

export enum Pages {
  index = 'index',
  about = 'about',
  contact = 'contact',
  services = 'services',
  process = 'process',
  privacy = 'privacy',
  notFound = 'notFound',
  caseStudy = 'caseStudy',
}
