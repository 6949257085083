import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { ColorNames, colors, validFontCategories } from 'utils/styles/theme';

interface TextProps {
  color: ColorNames;
  size: typeof validFontCategories[number];
}

export const Wrapper = styled(motion.a)`
  cursor: pointer;
`;

export const Text = styled(motion.h4)<TextProps>`
  color: ${({ color }) => colors[color]};
  ${({ size }) => setTypography(size)};
  position: relative;

  user-select: none;
  text-align: center;
`;

export const SvgWrapper = styled.div`
  display: grid;
  place-items: center;
  margin-top: 135rem;

  svg {
    width: 104px;
    height: 104px;
    color: ${colors.alizarinCrimson};
  }
`;
