import React, { MouseEvent } from 'react';

import Text from 'components/Text/Text';
import { ColorNames } from 'utils/styles/theme';

import * as S from './OvalButton.styles';

export interface OvalButtonProps {
  text: string;
  color?: ColorNames;
  backgroundColor?: ColorNames;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  tabIndex?: number;
}

const defaultProps: Partial<OvalButtonProps> = {
  text: 'accept',
  color: ColorNames.tallPoppy,
  backgroundColor: ColorNames.white,
  tabIndex: 0,
};

const OvalButton: React.FC<OvalButtonProps> = ({
  text,
  color,
  backgroundColor,
  onClick,
  tabIndex,
}) => {
  return (
    <S.OvalButtonWrapper
      onClick={onClick}
      backgroundColor={backgroundColor}
      type="button"
      role="button"
      aria-label={text}
      tabIndex={tabIndex || 0}
    >
      <S.Container>
        <Text as="span" text={text} size="button" color={color} />
      </S.Container>
    </S.OvalButtonWrapper>
  );
};

OvalButton.defaultProps = defaultProps;

export default OvalButton;
