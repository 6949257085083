import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  white: boolean;
}

interface CityProps {
  isContactPage?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 164rem;
  padding-top: 7rem;

  & > * {
    color: ${({ white }) => (white ? colors.white : colors.swamp)};
  }

  ${mediaTablet(`
    gap: 80rem;
  `)}

  ${mediaDesktop(`
    gap: 170rem;
    flex-direction: row;
  `)}
`;

export const SingleLocation = styled(motion.div)`
  display: grid;
  grid-column-gap: 124rem;
  grid-template-areas:
    'city city'
    'contact0 .'
    'contact1 .';

  ${mediaTablet(`
    gap: 80rem;
    grid-template-areas:
    'city city'
    'contact0 contact1';
  `)}
`;

export const City = styled(motion.div)<CityProps>`
  margin-bottom: 80rem;
  border-bottom: 1px solid ${colors.white + 'fff80'};
  padding: 20rem 0;

  ${({ isContactPage }) =>
    isContactPage
      ? mediaTablet(`
          padding: 0 0 20rem;
      `)
      : mediaTablet(`
          margin-bottom: 0;
  `)}
`;

export const ContactPerson = styled(motion.div)`
  margin-bottom: 80rem;

  & > p:first-of-type {
    font-weight: 700;
  }

  ${mediaTablet(`
    margin-bottom: 49rem;
  `)}
`;

export const Address = styled.div``;

export const Container = styled.div`
  width: max-content;
  display: grid;

  &:first-child {
    margin-bottom: 110rem;
  }

  ${mediaTablet(`
    &:first-child {
      margin-bottom: 0;
    }
  `)}
`;
