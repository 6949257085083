import React from 'react';

import Text from 'components/Text/Text';
import { SharedCopy } from 'store/copy.types';
import { ColorNames } from 'utils/styles/theme';

import * as S from './JavaScriptDisabled.styles';

interface JavascriptDisabled {
  sharedCopy: SharedCopy;
}

const JavascriptDisabled = ({ sharedCopy }: JavascriptDisabled) => {
  if (!sharedCopy.global) return null;

  const {
    global: {
      errors: { javascriptDisabled: copy },
    },
  } = sharedCopy;

  return (
    <S.Wrapper>
      <Text
        as="h1"
        text={copy.title}
        color={ColorNames.swamp}
        size="heading3"
      />
      <Text text={copy.body1} color={ColorNames.swamp} size="body2" />
      <Text text={copy.body2} color={ColorNames.swamp} size="body2" />
    </S.Wrapper>
  );
};

export default JavascriptDisabled;
