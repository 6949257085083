export const vertexShader = () => {
  return /* glsl */ `
          #ifdef GL_ES
            precision highp float;
          #endif
          #define PI 3.14159265359

          attribute float scale;
          attribute float isHovered;
          attribute float particlesSize;
          attribute float textureMapIndex;
          attribute float aRandomness;


          uniform vec2 u_mouse;
          uniform float u_time;
          uniform float u_pointsize;
          uniform float u_noise_amp_1;
          uniform float u_noise_freq_1;
          uniform float u_spd_modifier_1;
          uniform float u_noise_amp_2;
          uniform float u_noise_freq_2;
          uniform float u_spd_modifier_2;
          uniform vec2 u_resolution;

          varying float textureIndex;
          varying float aOpacity;
          varying vec2 vUv;


          // 2D Random
          float random (in vec2 st) {
              return fract(sin(dot(st.xy,
                                  vec2(12.9898,78.233)))
                          * 43758.5453123);
          }

          // 2D Noise based on Morgan McGuire @morgan3d
          // https://www.shadertoy.com/view/4dS3Wd
          float noise (in vec2 st) {
              vec2 i = floor(st);
              vec2 f = fract(st);

              // Four corners in 2D of a tile
              float a = random(i);
              float b = random(i + vec2(1.0, 0.0));
              float c = random(i + vec2(0.0, 1.0));
              float d = random(i + vec2(1.0, 1.0));

              // Smooth Interpolation

              // Cubic Hermine Curve.  Same as SmoothStep()
              vec2 u = f*f*(3.0-2.0*f);
              // u = smoothstep(0.,1.,f);

              // Mix 4 coorners percentages
              return mix(a, b, u.x) +
                      (c - a)* u.y * (1.0 - u.x) +
                      (d - b) * u.x * u.y;
          }

          mat2 rotate2d(float angle){
              return mat2(cos(angle),-sin(angle),
                        sin(angle),cos(angle));
          }

          vec3 mod289(vec3 x) {
            return x - floor(x * (1.0 / 289.0)) * 289.0;
        }

        vec4 mod289(vec4 x) {
            return x - floor(x * (1.0 / 289.0)) * 289.0;
        }

        vec4 permute(vec4 x) {
            return mod289(((x*34.0)+10.0)*x);
        }

        vec4 taylorInvSqrt(vec4 r)
        {
            return 1.79284291400159 - 0.85373472095314 * r;
        }

        float snoise(vec3 v)
        {
            const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
            const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

            // First corner
            vec3 i  = floor(v + dot(v, C.yyy) );
            vec3 x0 =   v - i + dot(i, C.xxx) ;

            // Other corners
            vec3 g = step(x0.yzx, x0.xyz);
            vec3 l = 1.0 - g;
            vec3 i1 = min( g.xyz, l.zxy );
            vec3 i2 = max( g.xyz, l.zxy );

            vec3 x1 = x0 - i1 + C.xxx;
            vec3 x2 = x0 - i2 + C.yyy;
            vec3 x3 = x0 - D.yyy;

            // Permutations
            i = mod289(i);
            vec4 p = permute( permute( permute(
                        i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                        + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
                        + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

            // Gradients: 7x7 points over a square, mapped onto an octahedron.
            // The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
            float n_ = 0.142857142857; // 1.0/7.0
            vec3  ns = n_ * D.wyz - D.xzx;

            vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

            vec4 x_ = floor(j * ns.z);
            vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

            vec4 x = x_ *ns.x + ns.yyyy;
            vec4 y = y_ *ns.x + ns.yyyy;
            vec4 h = 1.0 - abs(x) - abs(y);

            vec4 b0 = vec4( x.xy, y.xy );
            vec4 b1 = vec4( x.zw, y.zw );
            //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
            //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
            vec4 s0 = floor(b0)*2.0 + 1.0;
            vec4 s1 = floor(b1)*2.0 + 1.0;
            vec4 sh = -step(h, vec4(0.0));

            vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
            vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

            vec3 p0 = vec3(a0.xy,h.x);
            vec3 p1 = vec3(a0.zw,h.y);
            vec3 p2 = vec3(a1.xy,h.z);
            vec3 p3 = vec3(a1.zw,h.w);

            //Normalise gradients
            vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
            p0 *= norm.x;
            p1 *= norm.y;
            p2 *= norm.z;
            p3 *= norm.w;

            // Mix final noise value
            vec4 m = max(0.5 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
            m = m * m;
            return 105.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                        dot(p2,x2), dot(p3,x3) ) );
        }


          varying vec4 v_color;
          uniform float u_aspect;

          void main() {
            vec2 posUV = gl_Position.xy / gl_Position.w;
            vec2 mouse = u_mouse;
            mouse.y *= -1.;
            vec2 asp = vec2(u_aspect, 1.0);
            float d = distance(uv * asp, mouse * asp);

            vec3 pos = position;
            pos.z += noise(pos.xy * u_noise_freq_1 + u_time * u_spd_modifier_1) * u_noise_amp_1;
            pos.z += noise(rotate2d(PI / 4.) * pos.yx * u_noise_freq_2 - u_time * u_spd_modifier_2 * 0.6) * u_noise_amp_2;
            pos.z += (particlesSize / 40.5) * (pos.z * 0.02);
            pos.y += -(particlesSize / 20.0) * -(pos.y * 0.01);

            int lineNumber = int(abs(sign(pos.x)*floor(abs(pos.x+0.50))));


              if(lineNumber % 5 == 1){
                pos.y += sin(u_time / 1.5) * 0.025 +0.01;
              }
              if(lineNumber % 5 == 2){
                 pos.y += sin(u_time / 1.5) * 0.05 +0.01;
              }
              if(lineNumber % 5 == 3){
                 pos.y += sin(u_time / 1.5) * 0.075 +0.01;
              }
              if(lineNumber % 5 == 4){
                  pos.y += sin(u_time / 1.5) * 0.05 +0.01;
              }
              if(lineNumber % 5 == 0){
                  pos.y += sin(u_time / 1.5) * 0.025 +0.01;
                }
            vec4 mvm = modelViewMatrix * vec4(pos, 1.0);
            // if (isHovered > 1.0) {
            //   pos.x += 0.05;
            //   pos.y += 0.05;
            //   pos.z += 0.80;
            // }
            gl_Position = projectionMatrix * mvm * 0.05;


            gl_PointSize = 1.50 + abs(particlesSize * 0.15);

            vec2 reversedUv = vec2(uv.x, 1.0 - uv.y);
            float scaleFactorY = abs(reversedUv.y * 4.0);
            float scaleFactorX = abs(reversedUv.x * 8.0);
            gl_PointSize *= scaleFactorY;
            gl_PointSize *= scaleFactorX;

            vec3 color3 = vec3(0.05, 0.18, 0.20);
            vec3 color4 = vec3(0.05, 0.18, 0.20);
            float transitionFactor = smoothstep(0.05, 0.18, uv.y);
            vec3 color5 = vec3(0.04, 0.13, 0.15);

            vec4 col = vec4(0.17, 0.32, 1.00, 1.0);
            vec4 colHoverRed = vec4(0.00 + particlesSize / 6.0, 0.00, 1.00 - particlesSize / 6.0, 1.0);
            v_color = isHovered > 1.0 ? vec4(color5, 1.0) : vec4(color5, aRandomness);

            vUv = uv;
            textureIndex = textureMapIndex;
            aOpacity = aRandomness;
          }
          `;
};

export const fragmentShader = () => {
  return /* glsl */ `


          #ifdef GL_ES
          precision highp float;
          #endif
          #define PI 3.14159265359
          #define TWO_PI 6.28318530718
          #define SAMPLES 200.0




          varying vec2 vUv;
          varying float textureIndex;
          varying float aOpacity;



          varying vec4 v_color;
          uniform vec2 u_resolution;

          uniform sampler2D texture1;
          uniform sampler2D texture2;
          uniform sampler2D texture3;
          uniform sampler2D texture4;
          uniform sampler2D texture5;
          uniform sampler2D texture6;

          float random (in vec2 st) {
            return fract(sin(dot(st.xy,
                                vec2(12.9898,78.233)))
                        * 43758.5453123);
        }

        // 2D Noise based on Morgan McGuire @morgan3d
        // https://www.shadertoy.com/view/4dS3Wd
          float noise (in vec2 st) {
              vec2 i = floor(st);
              vec2 f = fract(st);

            // Four corners in 2D of a tile
            float a = random(i);
            float b = random(i + vec2(1.0, 0.0));
            float c = random(i + vec2(0.0, 1.0));
            float d = random(i + vec2(1.0, 1.0));

            // Smooth Interpolation

            // Cubic Hermine Curve.  Same as SmoothStep()
            vec2 u = f*f*(3.0-2.0*f);
            // u = smoothstep(0.,1.,f);

            // Mix 4 coorners percentages
            return mix(a, b, u.x) +
                    (c - a)* u.y * (1.0 - u.x) +
                    (d - b) * u.x * u.y;
            }

          vec4 selectedTexture(){
            vec4 texel = texture2D(texture1, gl_PointCoord);
            return vec4(0.142,0.262,0.490, texel.a * (aOpacity / 10.0));
          }

          void main() {


              vec4 tex = selectedTexture();
              float depth = gl_FragCoord.y / gl_FragCoord.w;
              float fogFactor = smoothstep(0.1, 800.0, depth);

              vec4 sum = vec4(0.0);
              float blur = 100.1 / u_resolution.x;

              // Sample the texture several times along a horizontal line and average the values


              gl_FragColor = vec4(mix( vec3(0.26, 0.56, 0.70), sum.rgb, fogFactor), tex.a);

          }
          `;
};
