import EventEmitter from 'events';
import { gsap } from 'gsap';
import { throttle } from 'lodash';
import {
  BlendFunction,
  DepthEffect,
  DepthOfFieldEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
  SelectiveBloomEffect,
  TiltShiftEffect,
} from 'postprocessing';
import * as THREE from 'three';

import { fragmentShader, vertexShader } from './shaders';

//14 on 1
const PARTICLES_IN_LINE = 80;

export default class App extends EventEmitter {
  _rendererEl: any;
  _renderer: any;
  _scene: any;
  _camera: any;
  _canvas: HTMLCanvasElement;
  _controls: any;
  _composer: any;
  _particles: any;
  _count: number;
  _moveBack: boolean;
  _raycaster: any;
  _mouse: any;
  _rect: any;
  _intersectionData: any;
  _plane: any;
  _hovered: any;
  _planeGeometry: any;
  _renderPass: any;
  _bloomLayer: any;
  _entireScene = 0;
  _bloomScene = 1;
  _bloomPass: any;
  _device: 'mobile' | 'tablet' | 'desktop';

  constructor({ rendererEl }) {
    super();
    this._rendererEl = rendererEl;
    this.createCamera();
    this._setupProject();
    this._renderPlane();
    this._onWindowResize();
  }

  _uniforms = {
    ...this._getDefaultUniforms(),
    u_pointsize: { value: 1.0 },
    // wave 1
    u_noise_freq_1: { value: 1.5 },
    u_noise_amp_1: { value: 0.6 },
    u_spd_modifier_1: { value: 0.014 },
    // wave 2
    u_noise_freq_2: { value: 1.5 },
    u_noise_amp_2: { value: 0.4 },
    u_spd_modifier_2: { value: 0.014 },
  };

  _onMouse = throttle(e => {
    this._mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    this._mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

    this._raycaster.setFromCamera(this._mouse, this._camera);

    const intersects = this._raycaster.intersectObjects([this._particles]);

    if (intersects.length > 0) {
      const object = intersects[0].object;
      this._intersectionData = intersects[0];
      const particleIndex = this._intersectionData.index;

      const size = { value: 0 };

      this._planeGeometry.attributes.isHovered.needsUpdate = true;

      const particlesIndexes = [
        particleIndex - 4,
        particleIndex - 3,
        particleIndex - 2,
        particleIndex - 1,
        particleIndex - 0,
        particleIndex - 646,
        particleIndex - 486,
        particleIndex - 485,
        particleIndex - 484,
        particleIndex - 326,
        particleIndex - 325,
        particleIndex - 324,
        particleIndex - 323,
        particleIndex - 322,
        particleIndex - 160,
        particleIndex - 161,
        particleIndex - 162,
        particleIndex - 163,
        particleIndex - 164,
        particleIndex - 165,
        particleIndex - 166,
        particleIndex + 158,
        particleIndex + 159,
        particleIndex + 160,
        particleIndex + 320,
      ];

      const animateParticles = index => {
        if (!this._planeGeometry.attributes.isHovered.array[index]) {
          this._planeGeometry.attributes.isHovered.array[index] = 2.0;
          this._planeGeometry.attributes.isHovered.needsUpdate = true;
          gsap.to(size, {
            value: 6,
            onUpdate: () => {
              this._planeGeometry.attributes.particlesSize.array[index] =
                size.value;
              this._planeGeometry.attributes.particlesSize.needsUpdate = true;
            },
            duration: 1.15,
            onComplete: () => {
              gsap.to(size, {
                value: 0,
                onUpdate: () => {
                  this._planeGeometry.attributes.particlesSize.array[index] =
                    size.value;
                  this._planeGeometry.attributes.particlesSize.needsUpdate =
                    true;
                },
                onComplete: () => {
                  this._planeGeometry.attributes.isHovered.array[index] = 0.0;
                  this._planeGeometry.attributes.isHovered.needsUpdate = true;
                },
                duration: 1.45,
              });
            },
          });
        }
      };

      particlesIndexes.forEach(particleIndex => {
        animateParticles(particleIndex);
      });

      this._plane.setFromNormalAndCoplanarPoint(
        this._camera.getWorldDirection(this._plane.normal),
        object.position
      );
    }
  }, 100);

  _setupProject() {
    this._canvas = document.createElement('canvas');
    this._rendererEl.appendChild(this._canvas);
    this._scene = new THREE.Scene();
    this._scene.background = new THREE.Color('#0d1214');
    this._renderer = new THREE.WebGLRenderer({
      canvas: this._canvas,

      powerPreference: 'high-performance',
      antialias: false,
      stencil: false,
      depth: false,
    });
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    this._renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this._renderer.setClearColor(0x0d1214, 1);

    this._raycaster = new THREE.Raycaster();
    this._raycaster.params.Points.threshold = 0.25; //0.035
    this._mouse = new THREE.Vector2();
    this._rect = this._rendererEl.getBoundingClientRect();
    this._plane = new THREE.Plane();
    this._hovered = null;

    this._composer = new EffectComposer(this._renderer);
    this._renderPass = new RenderPass(this._scene, this._camera);

    this._composer.addPass(this._renderPass);
    const dofEffect = new DepthOfFieldEffect(this._camera, {
      focusDistance: 0.999,
      focalLength: 0.005,
      bokehScale: 0.0,
    });
    const depthEffect = new DepthEffect({
      blendFunction: BlendFunction.SKIP,
    });
    const effectPass = new EffectPass(this._camera, dofEffect, depthEffect);

    this._composer.addPass(effectPass);
    //@ts-ignore
    this._scene.background = new THREE.Color('#02000d');

    const tiltShiftEffect = new TiltShiftEffect({
      rotation: 0.48,
      feather: 0.21,
      focusArea: 0.4,
      offset: 0.27,
      resolutionScale: 0.42,
    });

    const tiltShiftEffectPass = new EffectPass(this._camera, tiltShiftEffect);

    this._composer.addPass(tiltShiftEffectPass);

    // this._renderer.autoClear = false;

    window.addEventListener('mousemove', e => {
      this._onMouse(e);
    });

    window.addEventListener('touchmove', e => {
      this._onMouse(e);
    });

    const clock = new THREE.Clock();

    const animate = () => {
      requestAnimationFrame(animate);
      const elapsed = clock.getElapsedTime();
      this._uniforms.u_time.value = elapsed;
      this._composer.render();
      // this._renderer.render(this._scene, this._camera);
      if (this._device === 'mobile') {
        this._particles.position.x = 2.5;
        this._particles.position.z = 1;
      }
      if (this._device === 'tablet') {
        this._particles.position.x = 2.2;
        this._particles.position.z = 1.3;
      }
      if (this._device === 'desktop') {
        this._particles.position.z = 1.8;
        this._particles.position.x = 1;
      }
    };
    animate();
  }

  _getDefaultUniforms() {
    return {
      u_time: { value: 0.0 },
      u_mouse: {
        value: {
          x: 0.0,
          y: 0.0,
        },
      },
      u_resolution: {
        value: new THREE.Vector2(
          window.innerWidth * window.devicePixelRatio,
          window.innerHeight * window.devicePixelRatio
        ),
      },
      u_aspect: { value: window.innerWidth / window.innerHeight },
    };
  }

  _renderPlane() {
    this._planeGeometry = new THREE.PlaneGeometry(
      7 * 2,
      7,
      PARTICLES_IN_LINE * 2,
      PARTICLES_IN_LINE
    );

    const isHovered = new Float32Array(13041);
    const particlesSize = new Float32Array(13041);
    const textureMapIndex = new Float32Array(13041);
    const randomness = new Float32Array(13041);

    //setup bloom\

    const bloomEffect = new SelectiveBloomEffect(this._scene, this._camera, {
      blendFunction: BlendFunction.ADD,
      mipmapBlur: true,
      luminanceThreshold: 0.31,
      luminanceSmoothing: 0.06,
      intensity: 0.5,
      kernelSize: 0.15,
    });
    bloomEffect.inverted = true;
    const effectPassBloom = new EffectPass(
      this._camera,

      bloomEffect
    );
    this._composer.addPass(effectPassBloom);

    for (let i = 0; i < 13041; i++) {
      isHovered[i] = 0;
      particlesSize[i] = 0;
      textureMapIndex[i] = Math.floor(Math.random() * (6 - 1 + 1) + 1);
      randomness[i] = Math.floor(Math.random() * (6 - 1 + 1) + 1);
    }
    this._planeGeometry.setAttribute(
      'isHovered',
      new THREE.BufferAttribute(isHovered, 1)
    );
    this._planeGeometry.setAttribute(
      'particlesSize',
      new THREE.BufferAttribute(particlesSize, 1)
    );
    this._planeGeometry.setAttribute(
      'textureMapIndex',
      new THREE.BufferAttribute(textureMapIndex, 1)
    );
    this._planeGeometry.setAttribute(
      'aRandomness',
      new THREE.BufferAttribute(randomness, 1)
    );

    this._planeGeometry.attributes.particlesSize.needsUpdate = true;
    this._planeGeometry.attributes.isHovered.needsUpdate = true;
    this._planeGeometry.attributes.textureMapIndex.needsUpdate = false;

    const textureLoader = new THREE.TextureLoader();
    const textures = [1, 2, 3, 4, 5, 6].map(() => {
      return textureLoader.load('images/mask1.png');
    });

    const material = new THREE.ShaderMaterial({
      uniforms: {
        ...this._getDefaultUniforms(),
        ...this._uniforms,
        texture1: {
          type: 't',
          value: textures[0],
        },
        texture2: {
          type: 't',
          value: textures[1],
        },
        texture3: {
          type: 't',
          value: textures[2],
        },
        texture4: {
          type: 't',
          value: textures[3],
        },
        texture5: {
          type: 't',
          value: textures[4],
        },
        texture6: {
          type: 't',
          value: textures[5],
        },
      },
      vertexShader: vertexShader(),
      fragmentShader: fragmentShader(),
      transparent: true,
    });
    material.extensions.fragDepth = true;
    this._particles = new THREE.Points(this._planeGeometry, material);
    // this._particles.frustumCulled = false;
    this._particles.rotation.y = -0.15;
    this._particles.rotation.z = 0.3;
    this._particles.position.y = -2.3;
    this._particles.position.z = 1.8;
    this._particles.position.x = 1;

    this._scene.add(this._particles);
  }

  createCamera(
    fov = 60,
    near = 0.1,
    far = 1000,
    camPos = {
      x: 0.3382348870233376,
      y: -5.959049692403577,
      z: 3.5,
    },
    camLookAt = { x: 0, y: 0, z: 0 },
    aspect = window.innerWidth / window.innerHeight
  ) {
    this._camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this._camera.position.set(camPos.x, camPos.y, camPos.z);
    this._camera.lookAt(camLookAt.x, camLookAt.y, camLookAt.z); // this only works when there's no OrbitControls
    this._camera.rotation.x = 0.6804968957896708;
    this._camera.rotation.y = 0.2228551162249952;
    this._camera.rotation.z = -0.35853698782380563;
    this._camera.updateProjectionMatrix();

    window.addEventListener('resize', this._onWindowResize);
  }

  _onWindowResize = () => {
    if (window.innerWidth < 800) {
      this._device = 'mobile';
    }
    if (window.innerWidth >= 800 && window.innerWidth < 1300) {
      this._device = 'tablet';
    }
    if (window.innerWidth >= 1300) {
      this._device = 'desktop';
    }
    this._camera.aspect = window.innerWidth / window.innerHeight;
    this._camera.updateProjectionMatrix();
    this._composer.setSize(window.innerWidth, window.innerHeight);
  };
}
