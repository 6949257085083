import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    width: 100%;
    padding: 62rem 89rem;
    user-select: none;
    touch-action: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 62rem 89rem;
  position: relative;
`;

export const SeekBar = styled(motion.div)`
  height: 2px;

  background-color: ${colors.alizarinCrimson};

  position: relative;
  z-index: 1;
`;

export const UnderSeekBar = styled.div`
  height: 2px;

  position: absolute;
  inset: 0;
  margin: auto 89rem;

  background-color: ${colors.white}6;
`;

export const Pointer = styled.svg`
  position: absolute;
  bottom: -5rem;

  ${mediaTablet(`
    bottom: -11rem;
  `)}

  ${mediaDesktop(`
    bottom: -1%;
  `)}
`;
