import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';

import Button from 'components/Button/Button';
import { useCopyStore } from 'store';
import { SocialMediaProps } from 'store/copy.types';
import { opacityMotion } from 'utils/styles/animations';

import * as S from './SocialMedia.styles';

export interface SocialMediaDefaultProps {
  white?: boolean;
}

const defaultProps: Partial<SocialMediaDefaultProps> = {
  white: false,
};

export const VALID_TYPES = {
  ICON_BUTTON: 'iconButton',
  BUTTON_WITH_TEXT: 'buttonWithText',
};

const SocialMedia: FunctionComponent<SocialMediaDefaultProps> = ({ white }) => {
  const { copy } = useCopyStore();

  return (
    <S.Wrapper white={white}>
      <S.Label
        {...opacityMotion}
        transition={{
          delay: 0.3,
          duration: 0.4,
        }}
      >
        {copy.footer.name}
      </S.Label>
      {copy.footer.socialMedia?.map(
        ({ type, icon, buttonText, link }: SocialMediaProps, index: number) => (
          <motion.div
            key={index}
            {...opacityMotion}
            transition={{
              delay: 0.2 * (index + 1),
              duration: 0.5,
            }}
          >
            {type === VALID_TYPES.ICON_BUTTON ? (
              <Button icon={icon} link={link} />
            ) : (
              <Button label={buttonText} link={link} />
            )}
          </motion.div>
        )
      )}
    </S.Wrapper>
  );
};

SocialMedia.defaultProps = defaultProps;

export default SocialMedia;
