import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  white: boolean;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    color: ${({ white }) => (white ? colors.white : colors.swamp)};

    svg {
      width: 100%;
      height: 100%;
      g {
        path {
          fill: ${({ white }) => (white ? colors.white : colors.swamp)};
        }
      }
    }
  }

  & > div:first-of-type {
    width: 100%;
  }

  & > div > div:nth-child(n + 1) {
    border-color: ${({ white }) => (white ? colors.white : colors.swamp)};
  }

  ${mediaTablet(`
    justify-content: flex-start;
    gap: 30rem;

    & > div:first-of-type {
      width: auto;
    }
  `)}
`;

export const Label = styled(motion.div)`
  ${setTypography('labelSmall')};
`;
