import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as SvgArrow } from 'svgs/seeMoreArrow.svg';
import { fadeInUpMotion } from 'utils/styles/animations';
import { ColorNames, validFontCategories } from 'utils/styles/theme';

import * as S from './LinkButton.styles';

export interface LinkButtonProps {
  onClick: () => void;
  text: string;
  size: typeof validFontCategories[number];
  color?: ColorNames;
}

const defaultProps: Partial<LinkButtonProps> = {
  color: ColorNames.white,
};

const LinkButton: FunctionComponent<LinkButtonProps> = ({
  onClick,
  text,
  size,
  color,
}) => {
  const [wrapperRef, inView] = useInView({ threshold: [0.25, 0.5, 0.75] });

  return (
    <S.Wrapper
      ref={wrapperRef}
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyPress={e => e.key === 'Enter' && onClick()}
      initial={fadeInUpMotion.initial}
      animate={inView ? fadeInUpMotion.animate : fadeInUpMotion.initial}
      transition={{ duration: 0.3 }}
    >
      <S.Text
        size={size}
        color={color}
        dangerouslySetInnerHTML={{ __html: `${text}` }}
      />

      <S.SvgWrapper>
        <SvgArrow />
      </S.SvgWrapper>
    </S.Wrapper>
  );
};

LinkButton.defaultProps = defaultProps;

export default LinkButton;
